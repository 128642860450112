import React from 'react'

export default ({ data, isLoading }) => {
    if (isLoading) {
        return null
    } else {
        if (data.length) {
            return null
        } else {
            return <div className="noDataComponent">No Data Available</div>
        }
    }
}
