import React, { useContext } from 'react'
import { SpinnerIcon } from '../../../elem/Spinner'
import { DataContext } from './DataContext'

export default () => {
    const { layerLoading } = useContext(DataContext)
    if (layerLoading) {
        return (
            <div className="panelHeaderIconWrapper">
                <div className="panelHeaderIconDiv">
                    <SpinnerIcon extraClass={'panelHeaderIcon'} />
                </div>
            </div>
        )
    } else {
        return null
    }
}
