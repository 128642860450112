import AliasForm from './form/AliasForm'
import FacilityAssignment from './form/facilityAssignment/FacilityAssignment'
import SearchForm from './form/SearchForm'
import ReviewSubmission from './form/ReviewSubmission'

export default (formType, configuredNavGroups, isSearchTabVisible, isReview) => {
    const apiLink = formType.APILink
    
    const aliasTab = {
        groupName: `${formType.Name} Alias Information`,
        requires: null,
        type: 'component',
        props: {
            formType
        },
        component: AliasForm,
        dataAccessor: `${formType.PrimaryAccessor}Alias`
    }

    const searchTab = {
            groupName: `${formType.Name} Search`,
            requires: null,
            type: 'component',
            component: SearchForm,
            props: {
                formType
            },
            dataAccessor: formType.PrimaryAccessor,
        }
    
    const samplingPointAssignmentTab = {
            groupName: `Site Assignments`,
            requires: 'project',
            type: 'component',
            component: FacilityAssignment,
            props: {
                formType
            },
        }

    const reviewTab = {
            groupName: 'Review',
            requires: null,
            type: 'component',
            component: ReviewSubmission,
            dataAccessor: formType.PrimaryAccessor,
        }
    
    let allNavGroups = configuredNavGroups
    
    if (apiLink === 'facility') {
        allNavGroups = [
            aliasTab,
            ...allNavGroups
        ]
    } else if (apiLink === 'project') {
        allNavGroups = [
            aliasTab,
            ...allNavGroups,
            samplingPointAssignmentTab
        ]
    } else if (apiLink === 'result') {
        allNavGroups = [
            ...allNavGroups,
        ]
    }    
        
    if(isSearchTabVisible) {
        allNavGroups = [searchTab, ...allNavGroups]
    }

    if (isReview) {
        allNavGroups = [...allNavGroups, reviewTab]
    }

    return allNavGroups
} 