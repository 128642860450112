import React, { useContext } from 'react'

import TimeWindowSelectorButton from '../../../../elem/chart/TimeWindowSelectorButton'
import AnalyteSelectorButton from '../../../../elem/chart/AnalyteSelectorButton'
import ResetZoomButton from '../../../../elem/chart/ResetZoomButton'
import ResetDropdownButton from '../../../../elem/chart/ResetDropdownButton'

import { DataContext } from './../DataContext'

const ChartControls = () => {
    const {
        timeWindow,
        displayTimeWindowDropdown,
        toggleTimeWindowDropdown,
        displayAnalyteWindowDropdown,
        toggleAnalyteWindowDropdown,
        resetZoom,
        resetExpanded,
        toggleResetExpanded,
        visibleChartTab
    } = useContext(DataContext)
    return (
        <div className="chartControlsWrapper">
            {
                visibleChartTab === 'sampleResult' 
                ? (
                    <>
                        <ResetDropdownButton 
                            inHeader={false} 
                            resetExpanded={resetExpanded}
                            toggleResetExpanded={toggleResetExpanded} 
                        />
                        <AnalyteSelectorButton
                            inHeader={false} 
                            displayAnalyteWindowDropdown={displayAnalyteWindowDropdown}
                            toggleAnalyteWindowDropdown={toggleAnalyteWindowDropdown}
                        />
                        <TimeWindowSelectorButton
                            inHeader={false} 
                            timeWindow={timeWindow}
                            displayTimeWindowDropdown={displayTimeWindowDropdown}
                            toggleTimeWindowDropdown={toggleTimeWindowDropdown}
                        />
                    </>
                ) 
                : 
                <ResetZoomButton resetFunction={resetZoom} />
            }
        </div>
    )
}

export default ChartControls