import React from 'react'

import { UpArrow, DownArrow } from '../Arrows'

export default ({
    toggleAnalyteWindowDropdown,
    displayAnalyteWindowDropdown,
    inHeader=true
}) => {
    return (
        <div
            className="panelButton"
            onClick={() =>
                toggleAnalyteWindowDropdown(!displayAnalyteWindowDropdown)
            }
        >
            <span className="panelTimeOptionText">Analytes</span>
            <span className="panelTimeOptionDropdown">
                {displayAnalyteWindowDropdown ? <UpArrow className={`${inHeader ? 'is-white' : ''}`}/> : <DownArrow className={`${inHeader ? 'is-white' : ''}`}/>}
            </span>
        </div>
    )
}
