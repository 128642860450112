import React, { useContext } from 'react'

import { DataContext } from './DataContext'
import AnalyteSelector from '../../../../elem/chart/AnalyteSelector'
import { PanelStyleContext } from '../../../../elem/panel/PanelStyleContext'

export default () => {
    const {
        selectedAnalytes,
        toggleSelectedAnalyte,
        displayAnalyteWindowDropdown,
        toggleAnalyteWindowDropdown,
        analyteList
    } = useContext(DataContext)
    const { colorClass } = useContext(PanelStyleContext)

    if (!analyteList || !analyteList.length) {
        return null
    }

    return (
        <AnalyteSelector
            selectedAnalytes={selectedAnalytes}
            analyteList={analyteList}
            toggleSelectedAnalyte={toggleSelectedAnalyte}
            toggleAnalyteWindowDropdown={toggleAnalyteWindowDropdown}
            displayAnalyteWindowDropdown={displayAnalyteWindowDropdown}
            colorClass={colorClass}
        />
    )
}
