import React, { useContext } from 'react'
import { DataContext } from '../DataContext'

export default ({ formId }) => {
    const { isSubmitting } = useContext(DataContext)
    let buttonProps = {
        className: `button is-link is-medium ${isSubmitting ? 'is-loading' : ''}`,
        type: "submit"
    }
    if (formId) {
        buttonProps = {
            ...buttonProps,
            form: formId
        }
    }
    return (
        <div className="saveButtonWrapper">
            <button {...buttonProps}>
                Save
            </button>
        </div>
    )
}
