import React, { useContext } from 'react'

import PanelHeader from '../../../../elem/panel/PanelHeader'
import { DataContext } from './DataContext'
import TimeWindowSelectorButton from '../../../../elem/chart/TimeWindowSelectorButton'
import AnalyteSelectorButton from '../../../../elem/chart/AnalyteSelectorButton'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import { IconContext } from 'react-icons'
import { IconTooltip as Tooltip } from '../../../../elem/Tooltip'
import ResetDropdownButton from '../../../../elem/chart/ResetDropdownButton'

export default () => {
    const {
        timeWindow,
        displayTimeWindowDropdown,
        toggleTimeWindowDropdown,
        displayAnalyteWindowDropdown,
        toggleAnalyteWindowDropdown,
        analyteList,
        isCollapsed,
        toggleCollapsed,
        toggleResetExpanded,
        resetExpanded
    } = useContext(DataContext)

    return (
        <PanelHeader extraClass={'aggregatePanelHeader'}>
            <div className="panelHeaderText">Aggregate Analyte Chart</div>
            <div className="panelButtons">
                <ResetDropdownButton resetExpanded={resetExpanded} toggleResetExpanded={toggleResetExpanded} />
                {analyteList && analyteList.length ? (
                    <AnalyteSelectorButton
                        displayAnalyteWindowDropdown={
                            displayAnalyteWindowDropdown
                        }
                        toggleAnalyteWindowDropdown={
                            toggleAnalyteWindowDropdown
                        }
                    />
                ) : null}
                <TimeWindowSelectorButton
                    timeWindow={timeWindow}
                    displayTimeWindowDropdown={displayTimeWindowDropdown}
                    toggleTimeWindowDropdown={toggleTimeWindowDropdown}
                />
                <div
                    className={`panelHeaderIconDiv`}
                    data-tip={`Collapse / Expand`}
                    data-for={`displayCollapse`}
                    onClick={ () => toggleCollapsed() }
                >
                    <IconContext.Provider
                        value={{
                            className: `panelHeaderIcon ${
                                isCollapsed ? 'active' : 'inactive'
                            }`,
                        }}
                    >
                        {isCollapsed ? (
                            <IoIosArrowUp />
                        ) : (
                            <IoIosArrowDown />
                        )}
                    </IconContext.Provider>
                    <Tooltip id={'displayCollapse'} />
                </div>
            </div>
        </PanelHeader>
    )
}
