import React, { useContext} from 'react'

import { DataContext } from '../DataContext'
import TimeWindowSelector from '../../../../elem/chart/TimeWindowSelector'
import { PanelStyleContext } from '../../../../elem/panel/PanelStyleContext'

export default () => {
    const { timeWindow, setTimeWindow, displayTimeWindowDropdown, toggleTimeWindowDropdown } = useContext(DataContext)
    const { colorClass } = useContext(PanelStyleContext)
    return (
        <TimeWindowSelector
            timeWindow={timeWindow}
            setTimeWindow={setTimeWindow}
            toggleTimeWindowDropdown={toggleTimeWindowDropdown}
            displayTimeWindowDropdown={displayTimeWindowDropdown}
            colorClass={colorClass}
        />
    )
}