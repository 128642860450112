import moment from 'moment/moment'
import React from 'react'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { decapitalizeText } from '../stringUtils'

const getCellGSAIDObject = cell => { 
    return { GSAID: cell.row.values["GSA ID / Project Number"] }
}

const parseColumns = (data, dateColumns) => {
    if (data && data.length) {
        const exampleRow = data[0]
        const columns = Object.keys(exampleRow).map(col => {
            const isDate = dateColumns.indexOf(col) !== -1
            const sortType = isDate ? 'datetime' : 'alphanumeric'
            let returnColumn = !isDate ? ({
                Header: () => <div>{col}</div>,
                accessor: col,
                Cell: ({ cell }) => (<div>{cell.value}</div>),
            }) : ({
                Header: () => <div>{col}</div>,
                id: col, //https://stackoverflow.com/questions/53772900/getting-error-a-column-id-is-required-if-using-a-non-string-accessor-for-colum
                accessor: row => {
                    let returnDate
                    const momentDate = moment(row[col])
                    if (momentDate.isValid()) 
                        returnDate = momentDate.toDate()
                    else //hacky solution incoming: if it's not a valid date (i.e null) set the date to an absurdly high value so they get filtered to the bottom in no particular order https://stackoverflow.com/questions/60907412/how-to-sort-array-by-date-when-some-dates-are-null 
                        returnDate = moment('1/1/1002').toDate() // https://stackoverflow.com/questions/28993107/momentjs-how-to-prevent-invalid-date

                    return returnDate 
                }, //https://github.com/TanStack/table/issues/515  
                Cell: ({ cell }) => 
                    moment(cell.value).format('MM/DD/YYYY hh:mm:ss A').includes('01/01/1002') 
                        ? '' : moment(cell.value).format('MM/DD/YYYY hh:mm:ss A'), //MM/DD/YYYY hh:mm:ss A ... see hacky solution up there
                sortType
            })
            return returnColumn
        })
        return columns
    } else {
        return []
    }
}

const parseColumnsWithConfig = (data, config) => {
    if (data && data.length && config && config.length) {
        const exampleRow = data[0]
        const columns = Object.keys(exampleRow).map(col => {
            let columnName = col
            const configObj = config.find(
                x => decapitalizeText(x.ColumnName) === col
            )
            if (configObj) {
                columnName = configObj.ColumnTitle
            }
            return {
                Header: () => <div>{columnName}</div>,
                accessor: col,
                Cell: ({ cell }) => {
                    let value = cell.value
                    if (configObj) {
                        if (configObj.Values) {
                            var matchingConfigValue = configObj.Values.filter(
                                x => x.code !== null
                            ).find(
                                x =>
                                    x.code === value ||
                                    x.code.toString() === value.toString()
                            )

                            if (
                                matchingConfigValue &&
                                matchingConfigValue.codedescription
                            ) {
                                value = matchingConfigValue.codedescription
                            }
                        }
                    }
                    return <div>{value}</div>
                },
            }
        })
        return columns
    } else {
        return []
    }
}

const getInProgressColumns = (data, setConfirmDeleteModalData) => {
    const columns = parseColumns(data, ['Modify Date']).filter(
        x => x.accessor !== 'Link' && x.accessor !== 'IsValid'
    )
    return [
        {
            Header: () => <div>Validation Status</div>,
            accessor: 'IsValid',
            Cell: ({ cell }) => {
                const isValid = cell.row.values.IsValid === 'Pass'
                return (
                    <div>
                        {isValid ? (
                            <span className="icon-text">
                                <span className="icon">
                                    <i className="icon">
                                        <FaCheck className="has-text-success" />
                                    </i>{' '}
                                </span>
                                <span>Pass </span>
                            </span>
                        ) : (
                            <span className="icon-text">
                                <span className="icon">
                                    <i className="icon">
                                        <FaTimes className="has-text-danger" />
                                    </i>{' '}
                                </span>
                                <span>Fail </span>
                            </span>
                        )}
                    </div>
                )
            },
        },
        // {
        //     Header: () => <div>Form ID</div>,
        //     accessor: 'Form ID',
        //     Cell: ({ cell }) => {
        //         return <div>{cell.row.values['Form ID']}</div>
        //     },
        // },
        ...columns,
        {
            Header: () => <div>Actions</div>,
            accessor: 'nothing',
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ cell }) => {

                return (
                    <div>
                        <Link
                            to={{
                                pathname: cell.row.values.IsEDD
                                    ? `submissions/${cell.row.original.Link}/edd/${cell.row.values['Form ID']}`
                                    : `submissions/${cell.row.original.Link}/form/${cell.row.values['Form ID']}`,
                                state: getCellGSAIDObject(cell)
                            }}
                        >
                            Edit
                        </Link>
                        {` | `}
                        <div
                            className="link inline-block"
                            onClick={() =>
                                setConfirmDeleteModalData({ 
                                    display: true,
                                    uploadId: cell.row.values['Form ID'],
                                    formType: cell.row.values['Form Type'],
                                })
                            }
                        >
                            Delete
                        </div>
                    </div>
                )
            },
        },
    ]
}

const isForm = cell => (
        cell.row.values['Is EDD?'] === 'No' ||
        cell.row.values['Submitting EDD'] !== null
    )

const getSubmittedColumns = (data, isReviewer, hasPrintAccess, isPrinter) => {
    const columns = parseColumns(data, ["Submitted Date", "Approved / Denied Date"]).filter(x => x.accessor !== 'Link')
    return [
        ...columns,
        {
            Header: () => <div>Actions</div>,
            accessor: 'nothing',
            Cell: ({ cell }) => {
                return (
                    <div>
                        {isForm(cell) && !isPrinter && (
                            <Link
                                to={{
                                    pathname: `submissions/${cell.row.original.Link}/form/view/${cell.row.values['Form ID']}`,
                                    state: getCellGSAIDObject(cell)
                                }}
                            >
                                View
                            </Link>
                        )}
                        {isReviewer && !isPrinter && 
                            cell.row.values.Status === 'Submitted' && 
                            isForm(cell) && (
                                <>
                                    {` | `}
                                    <Link
                                        to={{
                                            pathname: `submissions/${cell.row.original.Link}/form/review/${cell.row.values['Form ID']}`,
                                            state: getCellGSAIDObject(cell)
                                        }}
                                    >
                                        Review
                                    </Link>
                                </>
                            )
                        }
                        {hasPrintAccess && cell.row.values['Form Type'] === 'Form 60' &&
                            isForm(cell) && (
                                <>
                                    <span className={`${isPrinter ? 'is-hidden' : ''}`}>{' | '}</span>
                                    <Link
                                        to={`submissions/${cell.row.original.Link}/form/print/${cell.row.values['Form ID']}`}
                                        target="_blank"
                                        rel="opener"
                                    >
                                        Print
                                    </Link>
                                </>
                            )}
                    </div>
                )
            },
        },
    ]
}

const getEmptyColumnObject = columns => {
    return columns.reduce((acc, curr) => {
        const field = curr.accessor
        return {
            ...acc,
            [field]: null,
        }
    }, {})
}

export {
    getInProgressColumns,
    getSubmittedColumns,
    parseColumns,
    getEmptyColumnObject,
    parseColumnsWithConfig,
}
