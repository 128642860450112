import React, { useContext } from 'react'
import { AppStateContext } from '../AppStateContext'

import PanelHeader, { close } from '../../../elem/panel/ModalPanelHeader'

export default () => {
    const { updateDetailState } = useContext(AppStateContext)
    const onClose = close.bind(this, updateDetailState, 'well')
    return (
        <PanelHeader
            onClose={onClose}
            headerClass={'wellDetailPanelHeader'}
            title={'Details'}
        />
    )
}
