import React from 'react'
import { FaAsterisk } from 'react-icons/fa'

export default ({ title, subtitle, displayRequiredText, requiredText, children }) => (
    <div className="formSectionWrapper">
        <div className="subtitle formSectionSubtitle">
            <div className="sectionTitle">{title}</div>
            {displayRequiredText && requiredText ? (
                <div className="help sectionDisclaimer">{requiredText} <span className="requiredStar has-text-danger"><FaAsterisk /></span></div>
            ) : null}
        </div>
        {subtitle ? (
            <p>
                <i>{subtitle}</i>
            </p>
        ) : null}
        {children}
    </div>
)
