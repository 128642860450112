import React, { useContext } from 'react'
import { DataContext } from './DataContext'
import { PanelStyleContext } from '../../../elem/panel/PanelStyleContext'
import withConfig from '../../../wrappers/withConfig'

const DetailTabs = ({config}) => {
    const { visibleDetailTab, setVisibleDetailTab } = useContext(DataContext)
    const { tabClass } = useContext(PanelStyleContext)
    const { FACILITY_TITLE } = config
    return (
        <div className={`tabs is-small is-boxed is-fullwidth ${tabClass}`}>
            <ul>
                <li
                    className={visibleDetailTab === 'well' ? 'is-active' : ''}
                    onClick={() => setVisibleDetailTab('well')}
                >
                    <div>
                        <span>{FACILITY_TITLE} Details</span>
                    </div>
                </li>
                <li
                    className={visibleDetailTab === 'construction' ? 'is-active' : ''}
                    onClick={() => setVisibleDetailTab('construction')}
                >
                    <div>
                        <span>Construction / Aquifers</span>
                    </div>
                </li>
                <li
                    className={visibleDetailTab === 'waterLevel' ? 'is-active' : ''}
                    onClick={() => setVisibleDetailTab('waterLevel')}
                >
                    <div>
                        <span>Water Level Measurements</span>
                    </div>
                </li>
                <li
                    className={visibleDetailTab === 'samples' ? 'is-active' : ''}
                    onClick={() => setVisibleDetailTab('samples')}
                >
                    <div>
                        <span>Sample Results</span>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default withConfig(DetailTabs)
