import React, { createContext } from 'react'

import { wellPanel as panelClassConfig } from '../../../../utils/styles/panelStyles'
import Explorer from '../../../elem/list/Explorer'

const DataContext = createContext(null) 

export default ({ width }) => {
    return (
        <Explorer 
            formName='well' 
            name='Well' 
            apiController='well' 
            DataContext={DataContext} 
            width={width} 
            panelTitle={'Site'}
            panelClassConfig={panelClassConfig}
        />
    )
}