import React, { useContext } from 'react'

import { parseColumnsWithConfig } from '../../../../utils/submissions/table'
import { SimpleSpinner } from '../../../elem/Spinner'
import SimpleTable from '../../../elem/table/SimpleTable'
import { ExistingEDDDataContext } from './ExistingEDDDataContext'
import Breadcrumbs from '../form/Breadcrumbs'
import { EDDDataContext } from './EDDDataContext'
import SectionWrapper from '../form/SectionWrapper'
import StatusBanner from '../StatusBanner'

export default () => {
    const { loading, data, uploadData } = useContext(ExistingEDDDataContext)
    const { eddConfig } = useContext(EDDDataContext)

    if (loading) {
        return <SimpleSpinner />
    }

    const columns = parseColumnsWithConfig(data, eddConfig)
    return (
        <div className="hero is-fullheight-with-navbar unset-justify-content">
            <Breadcrumbs />
            <div className="padding-left-sm padding-right-sm">
                <SectionWrapper title={'View EDD'}>
                    <StatusBanner uploadData={uploadData} />
                    <div className="formTableWrapper">
                        <SimpleTable data={data} columns={columns} />
                    </div>
                </SectionWrapper>
            </div>
        </div>
    )
}
