import React, { useContext} from 'react'

import { DataContext } from './DataContext'
import Modal from '../../elem/Modal'

export default () => {
    const { displayBeforePageChangeModal, setDisplayBeforePageChangeModal, pageChangeAction, setFormDirty, resetState } = useContext(DataContext)

    return (
        <Modal
            display={!!displayBeforePageChangeModal}
            confirmAction={() => {
                resetState()
                .then(() => {
                    setFormDirty(false)
                    pageChangeAction()
                })
                .then(setDisplayBeforePageChangeModal(false))
            }}
            confirmText={`Discard Changes`}
            denyAction={() => setDisplayBeforePageChangeModal(false)}
            denyText={`Cancel`}
            title={'Unsaved Changes'}
        >
            There are unsaved changes. Do you wish to discard these changes and navigate to the next page?
        </Modal>
    )
}
