import React, { useContext } from 'react'
import { DataContext } from './DataContext'
import ResetSelector from '../../../../elem/chart/ResetSelector'

const ResetDropdown = () => {
    const { resetZoom, resetAnalytes, resetExpanded, toggleResetExpanded } = useContext(DataContext)

    const onResetAnalytes = () => {
        resetAnalytes()
        toggleResetExpanded(false)
    }

    const onResetZoom = () => {
        resetZoom()
        toggleResetExpanded(false)
    }

    return (
        <ResetSelector
            resetExpanded={resetExpanded}
            resetAnalytesFunction={onResetAnalytes}
            resetZoomFunction={onResetZoom}
        />
    )
}

export default ResetDropdown