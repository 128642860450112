import React, { useState, createContext, useContext, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import toast from '../../../elem/Toast'
import withConfig from '../../../wrappers/withConfig'
import { UserContext } from '../../../wrappers/UserContext'
import { DataContext } from '../DataContext'
import actions from '../../../../utils/submissions/actions'
import { EDDDataContext } from './EDDDataContext'

const ExistingEDDDataContext = createContext(null)

const ExistingEDDDataContextProvider = ({ config, children }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { setActiveAgency, formType } = useContext(DataContext)
    const { setUploadId } = useContext(EDDDataContext)
    const { user } = useContext(UserContext)
    const [data, setData] = useState([])
    const [ uploadData, setUploadData ] = useState({})
    const [loading, setLoading] = useState([])
    const { API_URL } = config
    const { uploadId } = useParams()
    const history = useHistory()

    useEffect(() => {
        if (uploadId && actions.isValidFormType(formType)) {
            setLoading(true)
            authenticatedFetch(`${API_URL}/upload/${formType.APILink}/eddData?uploadId=${uploadId}`)
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setActiveAgency(response.upload.upload.AgencyCode)
                    setData(response.edd)
                    setUploadData(response.upload.upload)
                    setUploadId(uploadId)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                            'Existing EDD Data: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                })
                .finally(() => setLoading(false))
        }
    }, [uploadId, formType])

    const rejectUpload = useCallback((comments) => {
        if (uploadId && user && user.profile) {
            actions.reject(authenticatedFetch, API_URL, history, uploadId, comments, user.profile.name)
        }
    }, [uploadId, user])

    const approveUpload = useCallback(comments => {
        if (uploadId && user && user.profile) {
            actions.approve(authenticatedFetch, API_URL, history, uploadId, comments)
        }
    }, [uploadId, user])

    return (
        <ExistingEDDDataContext.Provider value={{ loading, data, setData, rejectUpload, approveUpload, uploadData }}>
            {children}
        </ExistingEDDDataContext.Provider>
    )
}

export { ExistingEDDDataContext }
export default withConfig(ExistingEDDDataContextProvider)
