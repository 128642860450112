import React, { useContext } from 'react'

import { DataContext } from './DataContext'
import UnitsDropdown from './UnitsDropdown'

const UnitsField = () => {
    const { searchRadius, setSearchRadius } = useContext(DataContext)
    return (
        <div className="field is-grouped is-grouped-centered is-fullwidth is-horizontal">
            <div className="field-label is-small">
                <label className="label has-text-weight-normal">Within a distance of:</label>
            </div>
            <div className="field-body">
                <div className="field">
                    <div className="control">
                        <input
                            type="number"
                            className="input is-small"
                            onChange={e => {
                                setSearchRadius(e.currentTarget.value)
                            }}
                            value={searchRadius}
                            name="distance"
                        />
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <UnitsDropdown />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnitsField
