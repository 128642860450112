import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react'
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import merge from 'lodash.merge'

import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import { DataContext } from '../DataContext'
import SelectStyles from '../../../elem/form/SelectStyles'
import withConfig from '../../../wrappers/withConfig'
import {
    aliasHelperText,
} from '../../../../utils/submissions/helperText'
import defaultUploadObjects from '../../../../utils/submissions/defaultUploadObjects'
import searchOptions from '../../../../utils/submissions/searchOptions'
import toast from '../../../elem/Toast'
import { getConfigRefCodeValue } from '../../../../utils/submissions/values'

const ExistingSearchComponent = withConfig(({ config, setAliasStateData, targetField, setTargetField, setContinueDisabled }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { activeAgency, setFormMethods, formType } = useContext(DataContext)
    const formMethods = useForm({ mode: 'onChange' })
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')
    const [valuesChanged, setValuesChanged] = useState(false)
    const [error, setError] = useState(null)
    const { register, handleSubmit } = formMethods
    const searchDisabled = useMemo(() => !value, [value])
    const { API_URL } = config
    const options = searchOptions[formType.PrimaryAccessor]

    useEffect(() => {
        if (value !== "" && !valuesChanged) {
            setContinueDisabled(false)
        } else {
            setContinueDisabled(true)
        }
    }, [valuesChanged, value])

    useEffect(() => {
        setFormMethods(formMethods)
    }, [])

    const search = (v, field) => {
        const option = options.find(x => x.value === field)
        if (v === '') {
            setError('Please enter a value.')
            setAliasStateData({})
            return
        }
        if (field === '' || field === null) {
            setError(`Please select an identifier for the existing ${formType.Singular}.`)
            setAliasStateData({})
            return
        }
        if (option.type === 'Int') {
            if (parseFloat(v) !== parseInt(v)) {
                setError('Value should be an integer.')
                setAliasStateData({})
                return
            }
            if (typeof(option.minValue) !== 'undefined' && option.minValue && parseInt(v) < option.minValue) {
                setError(`Value cannot be less than ${option.minValue}.`)
                setAliasStateData({})
                return
            }
        }
        setLoading(true)
        setAliasStateData({})
        authenticatedFetch(
            `${API_URL}/upload/${formType.APILink}/${formType.APILink}Info?columnName=${field}&columnValue=${v}&agencyCode=${activeAgency}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setAliasStateData(response[`${formType.APILink}Info`])
                setError(null)
                setValuesChanged(false)
            })
            .catch(e => {
                setError(e.message)
                setAliasStateData({})
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <form
                className="form"
                onSubmit={handleSubmit(() => search(value, targetField))}
            >
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-label">
                        <label className="label">{`Identify ${formType.Name} By:`}</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <Select
                                className="select is-multiple is-fullwidth reactSelect"
                                classNamePrefix="reactSelect"
                                options={options}
                                name="columnName"
                                // ref={register}
                                styles={SelectStyles}
                                menuPlacement="auto"
                                onChange={data => {
                                    setValue('')
                                    setTargetField(data.value)
                                    setValuesChanged(true)
                                }}
                            />
                        </div>
                        <div className="field">
                            <input
                                className={`input is-fullwidth`}
                                onChange={e => {
                                    setValuesChanged(true)
                                    setValue(e.target.value)
                                }}
                                value={value}
                                ref={register}
                                name="columnValue"
                            />
                        </div>
                        <div className="field">
                            <button
                                type="submit"
                                disabled={`${searchDisabled ? "true" : ""}`}
                                className={`button is-primary ${loading ? 'is-loading' : ''}`}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <div className="column is-12 has-text-centered">
                    {error ? <div className="has-text-danger">{error}</div> : null}
                </ div>
            </form>
        </>
    )
})

const NewSearchComponent = withConfig(({ config, setAliasStateData , setContinueDisabled }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { activeAgency, setFormMethods, formType } = useContext(DataContext)
    const [alias, setAlias] = useState(null)
    const [valuesChanged, setValuesChanged] = useState(false)
    const searchDisabled = useMemo(() => (!alias ), [alias])
    
    useEffect(() => {
        if ((!alias) || !valuesChanged) {
            setContinueDisabled(false)
        } else {
            setContinueDisabled(true)
        }
    }, [valuesChanged, alias])

    const [loading, setLoading] = useState(false)
    const formMethods = useForm({ mode: 'onChange' })
    const [error, setError] = useState(null)
    const { handleSubmit } = formMethods
    const { API_URL } = config

    useEffect(() => {
        setFormMethods(formMethods)
    }, [])

    const search = () => {
        setLoading(true)
        setAliasStateData({})
        authenticatedFetch(
            `${API_URL}/upload/${formType.APILink}/${formType.APILink}Search?alias=${alias}&agencyCode=${activeAgency}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                const aliasObj = {
                    Alias: alias
                }
                const data = merge({[`${formType.PrimaryAccessor}Alias`]: aliasObj}, response[`${formType.PrimaryAccessor}Info`])
                setAliasStateData(data)
                setError(null)
                setValuesChanged(false)
            })
            .catch(e => {
                setError(e.message)
                setAliasStateData({})
            })
            .finally(() => setLoading(false))
    }

    return (
        <form className="form" onSubmit={handleSubmit(d => search())}>
            <div className="is-size-4 has-text-centered matchingFacilityText">
                <span className="has-text-weight-semibold">{`${formType.Name} Identifiers: `}</span>
                <span className="help has-text-grey">
                    <p>
                        {`${formType.Name} Alias is an optional field. `}
                        {`If ${formType.Name} Alias is left blank, the ${formType.Singular} will be identified by the ${formType.NewlyCreatedIDName} after this form is accepted.`}
                    </p>
                </span>
            </div>
            <div className="columns is-vcentered">
                <div className="column is-12">
                    <div className="field is-horizontal is-align-items-center">
                        <div className="field-label">
                            <label className="label">{`${formType.Name}`} Alias:</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        className="input is-fullwidth "
                                        name={'Alias'}
                                        onChange={e => {
                                            setValuesChanged(true)
                                            setAlias(e.target.value)
                                        }}
                                    />
                                    <span className="help has-text-grey has-text-centered">
                                        {aliasHelperText
                                            .replace('%IDENTIFIER%', formType.Singular)
                                            .replaceAll('%NAME%', formType.Name)
                                            .replace('%SYSTEM%', formType.Name === 'Project' ? 'submitter’s numbering' : 'WaterStar')
                                        }
                                        </span>
                                </div>
                            </div>
                            <div className="field">
                                <button type="submit" disabled={searchDisabled ? true : ""} className={`button is-primary ${loading ? 'is-loading' : ''}`}>
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column is-12 has-text-centered">
                {error ? <div className="has-text-danger">{error}</div> : null}
            </ div>
        </form>
    )
})

const ToggleComponent = ({ updateFunction, setAliasStateData, facilityData, formType, setPrimaryKey }) => {
    return (
        <form className="form">
            <div className="field is-horizontal is-align-items-center">
                <div className="field-label">
                    <label className="label">New {`${formType.Name}`} to WaterSTAR?</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <Select
                            className="select is-multiple is-fullwidth reactSelect"
                            classNamePrefix="reactSelect"
                            options={[
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ]}
                            styles={SelectStyles}
                            menuPlacement="auto"
                            onChange={data => {
                                setAliasStateData({})
                                setPrimaryKey(null)
                                updateFunction(data.value)
                            }}
                            defaultValue={[ 
                                facilityData && facilityData.uploadFacilityId ?
                                { value: false, label: 'No' } : { value: true, label: 'Yes' } 
                            ]}
                            isDisabled={ facilityData && facilityData.uploadFacilityId ?  true : false }
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}

const FacilityInformation = withConfig(({ config, aliasStateData, duplicateAliasError, continueDisabled, viewOnly, uploadConfig }) => {
    const facilityData = aliasStateData
    const { FACILITY_TITLE } = config
    const [ alias, setAlias ] = useState()
    useEffect(() => {
        setAlias(
            facilityData && facilityData.facilityAlias
            ? facilityData.facilityAlias.alias
            : ''
        )
    }, [facilityData])
    
    if ((continueDisabled && !viewOnly) || !(facilityData.facility && Object.keys(facilityData.facility).some(x => facilityData.facility[x] != null)) ) {
        return null
    }


    return facilityData && facilityData.facility ? (
        <form className="form is-centered">
            <div className="is-size-4 has-text-weight-semibold has-text-centered matchingFacilityText">
                {FACILITY_TITLE} Information:{' '}
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Alias: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            <input
                                className="input is-small"
                                disabled
                                value={alias}
                            />
                            <p className="help has-text-gray">{aliasHelperText.replace('%IDENTIFIER%', FACILITY_TITLE.toLowerCase()).replaceAll('%NAME%', FACILITY_TITLE).replace('%SYSTEM%', 'submitter’s numbering')}</p>
                            {duplicateAliasError ? <p className="help is-danger ">{duplicateAliasError}</p> : null}

                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">GSA ID: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    facilityData && facilityData.facility
                                        ? facilityData.facility.facilityName
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">{ FACILITY_TITLE } Type: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    facilityData && facilityData.facility
                                        ? facilityData.facility.facilityType ? 
                                            getConfigRefCodeValue('FacilityType', facilityData.facility.facilityType, uploadConfig) : ''
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">County: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    facilityData && facilityData.facility
                                        ? facilityData.facility.countyKey ? getConfigRefCodeValue("CountyKey", facilityData.facility.countyKey, uploadConfig) : ''
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Section Township Range: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    facilityData && facilityData.facility && facilityData.facility.section && facilityData.facility.township && facilityData.facility.townshipDir && facilityData.facility.range && facilityData.facility.rangeDir
                                        ? facilityData.facility.section + ' ' + facilityData.facility.township + facilityData.facility.townshipDir + ' ' + facilityData.facility.range + facilityData.facility.rangeDir
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    ) : null
})

const ProjectInformation = ({ aliasStateData, duplicateAliasError, continueDisabled, viewOnly, uploadConfig }) => {
    const projectData = aliasStateData
    const [ alias, setAlias ] = useState()

    useEffect(() => {
        setAlias(
            projectData && projectData.project && projectData.project.projectAlias
            ? projectData.project.projectAlias.alias
            : ''
        )
    }, [projectData])

    if ((continueDisabled && !viewOnly) || !(projectData.project && Object.keys(projectData.project).some(x => projectData.project[x] != null)) ) {
        return null
    }

    return projectData && projectData.project ? (
        <form className="form is-centered">
            <div className="is-size-4 has-text-weight-semibold has-text-centered matchingFacilityText">
                Project Information:{' '}
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Project Alias: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            <input
                                className="input is-small"
                                disabled
                                value={alias}
                            />
                            <p className="help has-text-gray">{aliasHelperText.replace('%IDENTIFIER%', 'project').replace('%SYSTEM%', 'submitter’s numbering').replaceAll('%NAME%', 'Project')}</p>
                            {duplicateAliasError ? <p className="help is-danger ">{duplicateAliasError}</p> : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Project Number: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    projectData && projectData.project
                                        ? projectData.project.apiNumber
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Project Name: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    projectData && projectData.project
                                        ? projectData.project
                                              .projectName
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Project Type: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    projectData && projectData.project
                                        ?getConfigRefCodeValue('ProjectType', projectData.project.projectType , uploadConfig) 
                                        : projectData.project.projectType 
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    ) : null
}

const SaveAndContinueButton = ({ aliasStateData, duplicateAliasError, continueDisabled }) => {
    const { setSubmissionState, isSubmitting, setTableData, formType, setExistingSubmission } = useContext(DataContext)
    
    const emptyUploadState = defaultUploadObjects[formType.APILink]

    const mergeData = useCallback(() => {
        if (duplicateAliasError) {
            toast({
                level: 'error',
                message: 'Please fix errors before continuing.',
                alert: true
            })
        } else {
            setSubmissionState(prevSubmissionState => ({
                ...prevSubmissionState,
                ...emptyUploadState,
                newUploadFacility: Math.random() * 1000,
                ...aliasStateData
            }))
            setTableData({})
        }
    }, [aliasStateData, duplicateAliasError])

    return (
        <div className="buttonWrapper">
            <button
                type="button"
                onClick={() => {
                    setExistingSubmission(false)
                    mergeData()
                }}
                disabled={`${continueDisabled ? "true" : ""}`} 
                className={`button is-medium is-link ${isSubmitting ? 'is-loading' : ''}`}
            >
                {`Continue`}
            </button>
        </div>
    )
}

const getInformationComponent = accessor => {
    switch (accessor) {
        case 'facility':
            return FacilityInformation
        case 'project':
            return ProjectInformation
        default:
            return FacilityInformation
    }
}

const SearchForm = ({ formType }) => {
    const [newItem, setNewItem] = useState(true)
    const { activePanel, submissionState, viewOnly, isReview, setPrimaryKey, uploadConfig } = useContext(DataContext)
    const [ continueDisabled, setContinueDisabled] = useState(false)
    const [targetField, setTargetField] = useState(null)
    const [ aliasStateData, setAliasStateData ] = useState(submissionState)
    const [ duplicateAliasError ] = useState(null)

    useEffect(() => {
        setAliasStateData(submissionState)
    }, [submissionState])

    const InformationComponent = React.createElement(getInformationComponent(formType.PrimaryAccessor), {
        viewOnly,
        continueDisabled,
        aliasStateData,
        duplicateAliasError,
        targetField,
        uploadConfig
    })

    if (viewOnly || isReview) {
        return (
        <div className={`column is-12 ${activePanel !== `${formType.Name} Search` ? 'is-hidden' : ''}`}>
            <div className="columns is-centered">
                <div className="column is-6">
                    {InformationComponent}
                </div>
            </div>
        </div>
        )
    }
    
    return (
        <div
            className={`columns is-multiline ${
                activePanel !== `${formType.Name} Search` ? 'is-hidden' : ''
            }`}
        >
            {formType.FormType === 'SampleResults' ? (
                <div className="column is-12">
                    <ExistingSearchComponent setAliasStateData={setAliasStateData} targetField={targetField} setTargetField={setTargetField} setContinueDisabled={setContinueDisabled}/>
                </div>
            ) : (
                <>
                    <div className="column is-12">
                        <ToggleComponent updateFunction={d => setNewItem(d)} setAliasStateData={setAliasStateData} facilityData={submissionState.facility} setPrimaryKey={setPrimaryKey} formType={formType}/>
                    </div>
                    <div className="column is-12">
                        {newItem ? (
                            <NewSearchComponent setAliasStateData={setAliasStateData} setContinueDisabled={setContinueDisabled} />
                        ) : (
                            <ExistingSearchComponent setAliasStateData={setAliasStateData} targetField={targetField} setTargetField={setTargetField} setContinueDisabled={setContinueDisabled}/>
                        )}
                    </div>
                </>
            )}
            <div className="column is-12">
                <div className="columns is-centered">
                    <div className="column is-6">
                        {InformationComponent}
                    </div>
                </div>
            </div>
            <div className="column is-12">
                <SaveAndContinueButton aliasStateData={aliasStateData} duplicateAliasError={duplicateAliasError} continueDisabled={continueDisabled} />
            </div>
        </div>
    )
}

export default SearchForm
