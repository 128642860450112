import React from 'react'

import PanelHeader from '../../../elem/panel/PanelHeader'
import MapHeaderControls from './MapHeaderControls'
import MapLayerLoading from './MapLayerLoading'

export default () => {
    return (
        <PanelHeader extraClass={`mapPanelHeader`}>
            <div className="mapHeaderTextWrapper">
                <div className="mapHeaderText">
                    Map
                </div>
            <MapLayerLoading />
            </div>
            <MapHeaderControls />
        </PanelHeader>
    )
}