import React, { useContext, useEffect, useCallback, useMemo } from 'react'
import { useForm, FormContext } from 'react-hook-form'
import merge from 'lodash.merge'

import LoadingWrapper from '../../../wrappers/LoadingWrapper'
import { DataContext } from '../DataContext'
import Table from './TablePanel'
import Form from './FormPanel'
import SaveButton from './SaveButton'
import {
    getValuesFromData,
} from '../../../../utils/submissions/values'
import ResetButton from './ResetButton'
import { cloneDeep } from 'lodash'
import { setReactiveValues } from '../../../../utils/form/reactiveValues'
import { getRequiredText, shouldDisplayRequiredText } from '../../../../utils/submissions/helperText'

const getFormComponentType = type => {
    switch (type) {
        case 'Form':
            return Form
        case 'Table':
            return Table
        default:
            return null
    }
}

const FormComponent = ({ panelName, onSubmit, printable }) => {
    const {
        activePanel,
        uploadConfig,
        setFormMethods,
        setFormDirty,
        viewOnly,
        defaultValues
    } = useContext(DataContext)
    
    const formMethods = useForm({
        defaultValues: defaultValues,
        mode: 'onChange',
    })
    const { formState: { dirty }, watch } = formMethods
    const formValues = watch()

    const currentPageData = useMemo(() => {
        if (panelName || printable) {
            let relatedConfig = uploadConfig.filter(
                x => x.SectionGroupName === panelName
            )

            if (printable) {
                relatedConfig = uploadConfig
            }
            const subSections = [
                ...new Set(relatedConfig.map(x => x.UploadSectionGroupName)),
            ]
            return subSections.map((section, idx) => {
                const sectionData = relatedConfig.filter(
                    x => x.UploadSectionGroupName === section
                )
                const exampleRow = sectionData.length && sectionData[0]
                return {
                    config: sectionData,
                    type: exampleRow.SectionType,
                    accessor: exampleRow.DataAccessor,
                    title: exampleRow.UploadSectionGroupName,
                    subtitle: exampleRow.UploadSectionGroupDescription,
                    oneRowRequired: exampleRow.OneRowRequired,
                    printable,
                    key: `form-component-${idx}`,
                }
            })
        } else {
            return []
        }
    }, [panelName, uploadConfig, printable])

    const pageComponents = useMemo(() => {
        return currentPageData.map((d, idx) => {
            const componentType = getFormComponentType(d.type)
            const displayRequiredText = shouldDisplayRequiredText(d, idx)
            return React.createElement(componentType, {
                ...d,
                displayRequiredText,
                requiredText: getRequiredText(d)
            })
        })
    }, [currentPageData])

    useEffect(() => {
        if (activePanel === panelName) {
            setFormMethods(formMethods)
        }
    }, [activePanel])

    useEffect(() => {
        setFormDirty(dirty)
    }, [dirty])

    useEffect(() => {
        formMethods.reset(defaultValues)
    }, [activePanel, defaultValues])

    useEffect(() => {
        setReactiveValues(watch({ nest: true }), formMethods.setValue)
    }, [formValues])

    return (
        (activePanel === panelName || printable) && (
            <div>
                <LoadingWrapper data={currentPageData && currentPageData.length && pageComponents && pageComponents.length}>
                    <FormContext {...formMethods}>
                        <form
                            onSubmit={formMethods.handleSubmit(onSubmit)}
                            noValidate
                            className="columns is-multiline explorerForm is-centered"
                        >
                            {pageComponents}
                            {!viewOnly && !printable && (
                                <div className="column is-4 buttonWrapper">
                                    <SaveButton />
                                    <ResetButton />
                                </div>
                            )}
                        </form>
                    </FormContext>
                </LoadingWrapper>
            </div>
        )
    )
}

export default () => {
    const {
        navGroups,
        uploadConfig,
        tableData,
        submissionState,
        submitForValidation,
        printableView: printable
    } = useContext(DataContext)
    const onSubmit = useCallback(data => {
        // perform update to empty array if we set
        // the 'reset' flag on the form (corrseponds to empty array)
        Object.keys(data).map(key => {
            const associatedData = data[key]
            if (associatedData === 'reset') {
                data[key] = []
            } else if (associatedData === 'pageEmpty') {
                data[key] = tableData[key] ? tableData[key] : []
            }
            return null
        })

        // get the values from the data and update the submission state
        // as a merge of the values w/ submission state
        const values = getValuesFromData(data, uploadConfig)
        const copiedData = cloneDeep(submissionState)
        Object.keys(values).map(key => {
                const associatedData = values[key]
                const previousData = copiedData[key]
                if (Array.isArray(associatedData)) {
                    const td = cloneDeep(tableData[key])
                    if (associatedData === 'reset') {
                        copiedData[key] = []
                    } else {
                        const test = td ? merge(td, associatedData) : associatedData
                        copiedData[key] = test ? test : []
                    }
                } else {
                    copiedData[key] = merge(previousData, associatedData)
                }
                return null
            })
        submitForValidation(copiedData)
    }, [tableData, submissionState, submitForValidation])

    return (
        <div>
            {!printable &&
                navGroups.map((group, idx) => {
                    const key = `form-component-${idx}`
                    return group.type === 'component' ? (
                        React.createElement(group.component, {
                            key: key,
                            printable: printable,
                            ...group.props,
                        })
                    ) : (
                        <FormComponent
                            key={key}
                            printable={printable}
                            panelName={group.groupName}
                            onSubmit={onSubmit}
                        />
                    )
                })}
            {printable && (
                <FormComponent printable={printable} panelName={null} />
            )}
        </div>
    )
}
