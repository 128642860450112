const wellIdentifierHelperText =
    'Alias and DNR Well ID are optional fields. If the DNR Well ID is entered, it will be used to auto populate certain fields in this form using data from the DNR Registered Wells database. If Alias and DNR Well ID fields are left blank, the well will be identified by a Clearinghouse Number assigned after this form is accepted.'

const aliasHelperText =
    '%NAME% Alias is the name or number used by the submitter to identify this %IDENTIFIER%. The %NAME% Alias must be unique within the %SYSTEM% system.'

const reasonForEmptyDNRDataHelperText =
    'These fields will be blank if the well is not registered at DNR or the DNR Well ID was not entered in the Clearinghouse.'

const formRequiredText = 'Fields that are always required are indicated with a red asterisk'

const oneRowRequiredText = `At least 1 row is required for non-legacy Sites; ${formRequiredText.toLowerCase()}`

const constructionTablesText = `At least 1 row is required in each grid for non-legacy Sites; ${formRequiredText.toLowerCase()}`

const shouldDisplayRequiredText = (data, idx) => {
    const formType = data.type
    const printable = data.printable
    const oneRowRequired = data.oneRowRequired

    // do not display required text if the content is being viewed as printable only
    if (printable) {
        return false
    }

    // display required text for the first form on a page
    if (formType === 'Form' && idx === 0) {
        return true
    }

    // display required text for all tables
    if (formType === 'Table' && !oneRowRequired && idx === 0) {
        return true
    }
    if (formType === 'Table' && oneRowRequired) {
        return true
    }
    return false
}

const getRequiredText = data => {
    const { type, oneRowRequired, title } = data
    switch (type) {
        case 'Form':
            return formRequiredText
        case 'Table':
            if (oneRowRequired) {
                if (title === 'Site Casing' || title === 'Site Finish') { //determines if table is in the construction information tab
                    return constructionTablesText
                }
                return oneRowRequiredText
            } else {
                return formRequiredText
            }
        default:
            return ''
    }
}
export {
    wellIdentifierHelperText,
    aliasHelperText,
    reasonForEmptyDNRDataHelperText,
    shouldDisplayRequiredText,
    getRequiredText,
}
