import React, { useContext } from 'react'

import { DataContext } from './DataContext'
import Modal from '../../elem/Modal'
import urls from '../../../utils/constants/urls'

export default () => {
    const { displayContinueAnywayModal, setDisplayContinueAnywayModal } = useContext(DataContext)

    return (
        <Modal
            display={displayContinueAnywayModal}
            confirmAction={() => window.location = urls.manageSampleResults}
            confirmText={`Discard Changes`}
            denyAction={() => setDisplayContinueAnywayModal(false)}
            denyText={`Cancel`}
            title={'Unsaved Changes'}
        >
            This submission has unsaved changes and / or errors. Do you wish to discard these changes and return to the Submissions List?
        </Modal>
    )
}
