import React, { useContext } from 'react'
import { DataContext } from './DataContext'
import { getConfigRefCodeValue } from '../../../utils/submissions/values'
import SectionWrapper from './form/SectionWrapper'

const UploadInformation = () => {
    const { submissionState, uploadConfig } = useContext(DataContext)
    return submissionState && Object.keys(submissionState).length ? (
        <>
            <SectionWrapper title={'Upload Information'}>
                <div className="formWrapper">
                    <div className="column is-one-third">
                        <div className="formInputLayout">
                            <div className="field is-horizontal">
                                <div className="field-label is-small">
                                    <label className="label ">Form ID:</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <input
                                                className="input is-small is-printable"
                                                disabled
                                                value={submissionState.uploadId}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-third">
                        <div className="formInputLayout">
                            <div className="field is-horizontal">
                                <div className="field-label is-small">
                                    <label className="label ">
                                        Submitting Agency:{' '}
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control is-expanded">
                                            <input
                                                disabled
                                                className="input is-small is-printable"
                                                value={
                                                    submissionState &&
                                                    submissionState.agencyCode
                                                        ? getConfigRefCodeValue(
                                                              'AgencyCode',
                                                              submissionState.agencyCode,
                                                              uploadConfig
                                                          )
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-third">
                        <div className="formInputLayout">
                            <div className="field is-horizontal">
                                <div className="field-label is-small">
                                    <label className="label ">Site Alias:</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <input
                                                className="input is-small is-printable"
                                                disabled
                                                value={submissionState && submissionState.facilityAlias ? submissionState.facilityAlias.alias : null}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SectionWrapper>
        </>
    ) : null
}

export default UploadInformation
