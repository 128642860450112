import React, { useContext } from 'react'
import { DataContext } from '../DataContext'
import LoadingWrapper from '../../../wrappers/LoadingWrapper'
import Content from './Content'
import StatusBanner from '../StatusBanner'
import UploadInformation from '../UploadInformation'

const SimpleView = () => {
    const { uploadConfig, submissionState } = useContext(
        DataContext
    )
    return (
        <LoadingWrapper data={uploadConfig}>
            <div className="column is-12">
                <StatusBanner
                    uploadData={
                        submissionState && submissionState.upload
                            ? submissionState.upload
                            : null
                    }
                />
                <UploadInformation />
                <Content />
            </div>
        </LoadingWrapper>
    )
}

export default SimpleView
