const clickSelection = (e, row, page, selectedRows, allRows, lastSelection, rowSelector, updateSelectedRows, setLastSelection) => {
    const shiftKeyPressed = e.shiftKey
    const altKeyPressed = e.altKey
    let newFeatures = selectedRows
    const rowSelected = !!selectedRows.find(x => x[rowSelector] === row.original[rowSelector])

    // if neither shift nor alt are held (click only):
    //  if row is selected, disable it
    //  if row not selected, enable it and disable previous selection
    //    additionally set the "lastSelection" for shift click preparation
    if (!shiftKeyPressed && !altKeyPressed) {
        if (rowSelected) {
            newFeatures = []
            setLastSelection(null)
        } else {
            const newFeature = allRows.find(x => x[rowSelector] === row.original[rowSelector])
            newFeatures = [newFeature]

            // set last selection to be the feature we've enabled
            setLastSelection(row)
        }
    }

    // if alt is held (alt + click):
    //  if row is selected, remove it from the selected list
    //  if row is not selected, add it to the selected list
    //    additionally set the "lastSelection" for shift click preparation
    if (!shiftKeyPressed && altKeyPressed) {
        if (rowSelected) {
            // if the row is already selected, remove it from the
            // selectedFeatures array on click
            newFeatures = selectedRows.filter(
                x => x[rowSelector] !== row.original[rowSelector]
            )
        } else {
            // if the row is not selected, add it to the selectedFeatures
            // array on click
            const newFeature = allRows.find(
                x => x[rowSelector] === row.original[rowSelector]
            )
            newFeatures = [...selectedRows, newFeature]

            // set last selection to be the feature we've just clicked
            setLastSelection(row)
        }
    }

    // if shift is pressed (shift + click):
    //  * requires the knowledge of the last selected 
    //  get the idx of the current row, the idx of the previous row, and 
    //  set all rows in between the two as selected
    if (shiftKeyPressed && !altKeyPressed) {
        // if there is a last selection, shift acts like a select between
        if (lastSelection) {
            const lastSelectionCurrentIndex = page.findIndex(x => x.original[rowSelector] === lastSelection.original[rowSelector])
            const clickedIndex = page.findIndex(x => x.original[rowSelector] === row.original[rowSelector])
            const from = clickedIndex > lastSelectionCurrentIndex ? lastSelectionCurrentIndex : clickedIndex
            const to = clickedIndex > lastSelectionCurrentIndex ? clickedIndex + 1 : lastSelectionCurrentIndex + 1
            const newFeatureSelectors = page.slice(from, to).map(x => x.original[rowSelector])
            newFeatures = allRows.filter(x => newFeatureSelectors.includes(x[rowSelector]))
        } else {
            // if there is no last selection, shift acts like a first click
            const newFeature = allRows.find(x => x[rowSelector] === row.original[rowSelector])
            newFeatures = [newFeature]

            // set last selection to be the feature we've enabled
            setLastSelection(row)
        }
    }
    updateSelectedRows(newFeatures)
}

export default clickSelection