const convertFromParams = (sortByField, sortOrder) => {
    if (typeof sortByField === 'undefined') {
        return []
    }
    return [
        {
            id: sortByField,
            desc: sortOrder === 'DESC'
        }
    ]
}
const getRowValuesByColumnID = (row1, row2, columnId) => {
    return [row1.values[columnId], row2.values[columnId]]
  }
  
const toString = a => {
  if (typeof a === 'number') {
    if (isNaN(a) || a === Infinity || a === -Infinity) {
      return ''
    }
    return String(a)
  }
  if (typeof a === 'string') {
    return a
  }
  return ''
}

const string = (rowA, rowB, columnId) => {
    let [a, b] = getRowValuesByColumnID(rowA, rowB, columnId)
  
    a = toString(a).split('').filter(Boolean)
    b = toString(b).split('').filter(Boolean)
  
    while (a.length && b.length) {
      let aa = a.shift()
      let bb = b.shift()
  
      let alower = aa.toLowerCase()
      let blower = bb.toLowerCase()
  
      // Case insensitive comparison until characters match
      if (alower > blower) {
        return 1
      }
      if (blower > alower) {
        return -1
      }
      // If lowercase characters are identical
      if (aa > bb) {
        return -1
      }
      if (bb > aa) {
        return 1
      }
      continue
    }
  
    return a.length - b.length
  }
  

const getSortType = (columnName, filterFields) => {
    const columnData = filterFields.find(x => x.ColumnName === columnName || x.ControlName === columnName)
    if (columnData) {
        const dataType = columnData.DataType
        switch (dataType) {
            case 'String': 
                return string
            case 'Int':
                return 'alphanumeric'
            case 'Float':
                return 'alphanumeric'
            case 'Date':
                return 'datetime'
            default:
                return 'alphanumeric'
        }
    }
    return 'alphanumeric'
}

export { convertFromParams, getSortType }