import React, { createContext, useCallback, useState, useContext, useMemo } from 'react'
import { ParameterContext } from '../../wrappers/ParameterContext'

const AppStateContext = createContext(null)

const AppStateContextProvider = ({ children }) => {
    const { params, setParams } = useContext(ParameterContext)
    const [promotedRecords, setPromotedRecords] = useState({'Sample': false, 'Well': false, 'WaterLevel': false})
    const [collapsedExplorers, setCollapsedExplorers] = useState({'Sample': false, 'Well': false, 'WaterLevel': false})
    const [detailState, setDetailState] = useState({
        well: {
            visible: false,
            facilityID: null,
        },
        hydrograph: {
            visible: false,
            facilityID: null
            // DEV: This is for testing
            // visible: true,
            // facilityID: 92278
        }
    })

    const [filterState, setFilterState] = useState({
        well: false,
        sample: false,
        waterLevel: false
    })

    const [mapState, setMapState] = useState({
        selectedFeatures: [],
        allFeatures: [],
        filterFeatures: [],
        mapSelectionIds: "",
        map: null,
    })

    const updateDetailState = (name, newState) => {
        const newDetailState = {
            [name]: {
                ...detailState[name],
                ...newState,
            },
        }
        setDetailState({
            ...detailState,
            ...newDetailState,
        })
    }

    const updateFilterState = (name, newState) => {
        setFilterState({
            ...filterState,
            [name]: newState
        })
    }

    const syncFilterState = newState => {
        const newFilters = Object.keys(filterState).map(filter => ({[filter]: newState}))
        setFilterState(newFilters)
    }

    const toggleCollapsed = (collapsed, field) => {
        setCollapsedExplorers({...collapsedExplorers, [field]: collapsed})
    }

    const allExplorersPromoted = useMemo(() => {
        return Object.values(promotedRecords).every(e => e === true)
    }, [promotedRecords])

    const togglePromotedRecords = useCallback((promoted, field) => {
        const newParams = {
            ...params.app,
            [`${field}Promoted`]: promoted
        }
        setPromotedRecords({...promotedRecords, [field]: promoted})
        setParams(newParams, 'app')
    }, [params, promotedRecords])

    const promoteAllExplorers = useCallback((promoted) => {
                const newParams = {
                    ...params.app,
                    [`SamplePromoted`]: promoted,
                    [`WaterLevelPromoted`]: promoted,
                    [`WellPromoted`]: promoted,
                }
                setPromotedRecords({...promotedRecords, 
                    [`Sample`]: promoted,
                    [`WaterLevel`]: promoted,
                    [`Well`]: promoted,})
                setParams(newParams, 'app')
    }, [params, promotedRecords])

    return (
        <AppStateContext.Provider
            value={{
                detailState,
                updateDetailState,
                mapState,
                setMapState,
                filterState,
                updateFilterState,
                syncFilterState,
                promotedRecords, 
                togglePromotedRecords,
                promoteAllExplorers,
                allExplorersPromoted,
                toggleCollapsed,
                collapsedExplorers,
                setCollapsedExplorers,
            }}
        >
            {children}
        </AppStateContext.Provider>
    )
}

export { AppStateContext }
export default AppStateContextProvider
