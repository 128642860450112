import React from 'react'
import { FaTimes } from 'react-icons/fa'

import PanelHeader from './PanelHeader'

const close = (updateAppState, detailTarget, e) => {
    e.preventDefault()
    updateAppState(detailTarget, { visible: false })
}

export { close }
export default ({ onClose, headerClass, title, children }) => {
    return (
        <PanelHeader extraClass={headerClass}>
            <div className="panelHeaderText">{title}</div>
            <div className="panelButtons">
                {children}
                <div className="panelButton" onClick={e => onClose(e)}>
                    <FaTimes />
                </div>
            </div>
        </PanelHeader>
    )
}
