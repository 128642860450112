import React, { useContext } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa'

import urls, { createSubmissionUrl } from '../../../utils/constants/urls'
import Breadcrumbs from './form/Breadcrumbs'
import { DataContext } from './DataContext'
import { SimpleSpinner } from '../../elem/Spinner'

export default () => {
    const { type } = useParams()
    const history = useHistory()
    const { formTypes } = useContext(DataContext)
    if (!formTypes) {
        return <SimpleSpinner />
    }

    const formType = formTypes.find(x => x.Link === type)
    if (!formType.ShowSelectionDialogue) {
        history.push(createSubmissionUrl(urls.form, type))
    }
    return (
        <div className="hero is-fullheight-with-navbar">
            <Breadcrumbs />
            <div className="hero-body">
                <div className="container">
                    <div className="section">
                        <div className="columns is-centered is-vcentered">
                            <div className="column is-6">
                                <article className="message is-primary">
                                    <div className="message-header space-between">
                                        <Link
                                            to={createSubmissionUrl(
                                                urls.edd,
                                                type
                                            )}
                                            className="no-underline full-width"
                                        >
                                            <div className="headerWrapper space-between">
                                                <span className="headerText display-flex center-vertically">
                                                    {formType.EDDHeader}
                                                </span>
                                                <span className="iconWrapper icon is-medium">
                                                    <FaChevronRight />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="message-body">
                                        {formType.EDDDescription}
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="columns is-centered is-vcentered">
                            <div className="column is-6">
                                <article className="message is-link">
                                    <div className="message-header space-between">
                                        <Link
                                            to={createSubmissionUrl(
                                                urls.form,
                                                type
                                            )}
                                            className="no-underline full-width"
                                        >
                                            <div className="headerWrapper space-between">
                                                <span className="headerText display-flex center-vertically">
                                                    {formType.FormHeader}
                                                </span>
                                                <span className="iconWrapper icon is-medium">
                                                    <FaChevronRight />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="message-body">
                                        {formType.FormDescription}
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
