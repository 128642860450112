import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react'

import withConfig from '../../../../wrappers/withConfig'
import { AppStateContext } from '../../AppStateContext'
import { ParameterContext } from '../../../../wrappers/ParameterContext'
import { APIRequestContext } from '../../../../wrappers/APIRequestContext'
import filterTimeData, { timeWindowOptions } from '../../../../../utils/chart/timeWindow'
import toast from '../../../../elem/Toast'
import { generateDateParams } from '../../../../../utils/chart/values'
import getChartConfig from '../../../../../utils/chart/getChartConfig'

const DataContext = createContext(null)

export default withConfig(({ config, children }) => {
    const { mapState } = useContext(AppStateContext)
    const { params } = useContext(ParameterContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [chartData, setChartData] = useState([])
    const [tooManyFeatures, setTooManyFeatures] = useState(false)
    const [zoomTrigger, setZoomTrigger] = useState(false)
    const [isCollapsed, setCollapsed] = useState(false)
    
    const [loading, setLoading] = useState(false)
    const { selectedFeatures } = mapState
    const { API_URL, ID_COLUMN } = config
    const { dateField } = getChartConfig(config, 'WATER_LEVEL')
    const dateParams = useMemo(() => {
        const d = generateDateParams(params, 'waterLevel', `${dateField}`)
        return d.some(x => x !== null) ? d : null
    }, [params, dateField])

    const selectedFeaturesString = useMemo(() => {
        return selectedFeatures.map(x => x.get(ID_COLUMN)).sort().toString()
    }, [selectedFeatures])

    const toggleCollapsed = () => {
        setCollapsed(!isCollapsed)
    }
    
    const fetchChartData = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            const [startDate, endDate] = dateParams ? dateParams : [null, null]
            const facilityIDs = encodeURI(
                selectedFeaturesString
            )

            const body = JSON.stringify({
                facilityIDs,
                StartDate: startDate,
                EndDate: endDate,
            })
            await authenticatedFetch(`${API_URL}/waterLevelChart`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers':
                        'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                },
                body,
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setChartData(response.chartData)
                    return resolve(response.chartData)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                        'Water Level Chart: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                        })
                    return reject()
                })
        })
    }, [selectedFeaturesString, dateParams])
     
    // when the selected features change or the time window changes, go fetch the 
    // water level chart data
    useEffect(() => {
        const updatedIds = selectedFeaturesString.split(',')
        if (selectedFeaturesString !== '' && updatedIds.length) {
            // we will limit the number of  that can be supplied
            // to a query. if there is a workaround, this check can be removed
            if (updatedIds.length < 10) {
                setLoading(true)
                setTooManyFeatures(false)
                fetchChartData()
                    .then(() => setLoading(false))
                    .catch(e => setLoading(false))
            } else {
                setTooManyFeatures(true)
            }
        } else {
            // if there are no features,
            // set data to an empty array
            setChartData([])
        }
    }, [selectedFeaturesString, dateParams])

    const waterLevelChartData = useMemo(() => {
        if (chartData && chartData.length) {
            return dateParams
                ? filterTimeData(
                      chartData,
                      dateParams,
                      dateField
                  )
                : filterTimeData(
                      chartData,
                      timeWindowOptions[0],
                      dateField
                  )
        }
        return []
    }, [chartData, dateParams])

    const resetZoom = useCallback(() => {
        setZoomTrigger(!zoomTrigger)
    }, [zoomTrigger])

    return (
        <DataContext.Provider
            value={{
                loading,
                chartData,
                tooManyFeatures,
                waterLevelChartData,
                resetZoom,
                zoomTrigger,
                isCollapsed,
                setCollapsed,
                toggleCollapsed
            }}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }
