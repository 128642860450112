import React, { useContext, useMemo } from 'react'

import { DataContext } from '../DataContext'

export default () => {
    const { submit, isSubmitting, primaryKey } = useContext(DataContext)

    const buttonDisabled = useMemo(
        () => primaryKey == null || isNaN(primaryKey),
        [primaryKey]
    )

    return (
        <div className="submitButtonWrapper">
            <button
                className={`button is-link ${isSubmitting ? 'is-loading' : ''}`}
                type="button"
                onClick={() => submit()}
                disabled={buttonDisabled ? true : false}
            >
                {`Submit`}
            </button>
        </div>
    )
}
