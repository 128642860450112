import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import duration from 'dayjs/plugin/duration'
dayjs.extend(advancedFormat)
dayjs.extend(duration)

const defaultFormat = 'MM/DD/YYYY'

const monthFormat = 'MMM'

const monthAndDayFormat = 'MMM DD'
const monthAndDayHRFormat = 'MMM Do'


const monthAndDayTickFormatter = (value, minXValue, maxXValue, defaultMinXValue, defaultMaxXValue) => {
    if (minXValue === defaultMinXValue && maxXValue === defaultMaxXValue) {
        // if we are viewing the whole chart, format x ticks like "Jan, Feb, etc..."
        return dayjs(value).format(monthFormat).toString()
    } else {
     // if we are viewing less than 
        return dayjs(value).format(monthAndDayFormat).toString()
    }
}

const minWaterDay = new dayjs('10-01-2001').toDate().getTime()
const maxWaterDay  = new dayjs('09-30-2002').toDate().getTime()


const calculateMonthDayTicks = (minTime, maxTime) => {
    if (minTime && maxTime) {
        let ticks = []
        const minDate = dayjs(minTime)
        const maxDate = dayjs(maxTime)
        const numMonths = maxDate.diff(minDate, 'month')
        const numDays = maxDate.diff(minDate, 'day')
        if (numMonths > 1) {
            // if timespan > 1 month, display ticks for every month
            for (let i = 0; i < numMonths + 1; i++ ){
                ticks.push(minDate.add(i, 'month').toDate().getTime())
            }
            return ticks
        } 
        if (numMonths <= 1 && numDays > 15 ) {
            return [minDate.toDate().getTime(), minDate.add(numDays, 'day').toDate().getTime()]
        }
        else {
            // if timespan < 1 month, display ticks for every day in the timespan
            for (let i = 0; i < numDays + 1; i++ ) {
                ticks.push(minDate.add(i, 'day').toDate().getTime())
            }
            return ticks
        }

    }
    return []
}

export { defaultFormat, monthFormat, monthAndDayFormat, monthAndDayHRFormat, monthAndDayTickFormatter, minWaterDay, maxWaterDay, calculateMonthDayTicks}