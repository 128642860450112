import React, { useContext } from 'react'
import { PanelStyleContext } from '../../panel/PanelStyleContext'

export default ({
    comparisonOperators,
    comparisonOperatorName,
    register,
    unregister,
    initialComparisonOperatorValue,
    checkForBetweenOperator,
    between,
}) => {
    const { selectDropDownCaratClass } = useContext(PanelStyleContext)
    if (between) {
        unregister(comparisonOperatorName)
        return (
            comparisonOperators && (
                <div className={`control ${selectDropDownCaratClass}`}>
                    <div className="select is-small formComparisonOperatorWrapper">
                        <select
                            onChange={checkForBetweenOperator}
                            className="formSelect"
                            defaultValue={'BETWEEN'}
                            value={'BETWEEN'}
                        >
                            {comparisonOperators.map((operator, idx) => (
                                <option value={operator} key={`option-${idx}`}>
                                    {operator.toUpperCase()}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="is-hidden">
                        <select
                            name={`${comparisonOperatorName}[0]`}
                            ref={register}
                            defaultValue={'>='}
                        >
                            <option value={'>='}>{'>='}</option>
                        </select>
                        <select
                            name={`${comparisonOperatorName}[1]`}
                            ref={register}
                            defaultValue={'<='}
                        >
                            <option value={'<='}>{`<=`}</option>
                        </select>
                    </div>
                </div>
        )
    )
    } else {
        unregister(`${comparisonOperatorName}[0]`)
        unregister(`${comparisonOperatorName}[1]`)
        return (
            comparisonOperators && (
                <div className={`control ${selectDropDownCaratClass}`}>
                    <div className="select is-small formComparisonOperatorWrapper">
                        <select
                            name={comparisonOperatorName}
                            onChange={checkForBetweenOperator}
                            className="formSelect"
                            ref={register}
                            defaultValue={
                                initialComparisonOperatorValue
                            }
                            >
                            {comparisonOperators.map(
                                (operator, idx) => (
                                    <option
                                    value={operator}
                                    key={`option-${idx}`}
                                    >
                                        {operator.toUpperCase()}
                                    </option>
                                )
                                )}
                        </select>
                    </div>
                </div>
            )
        )
    }
}