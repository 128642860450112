import React, { useContext, useMemo } from 'react'

import {
    uploadFieldParser,
    uploadInputSelector,
} from '../../../../utils/form/formFieldParser'
import { DataContext } from '../DataContext'
import SectionWrapper from './SectionWrapper'

export default ({ config, title, subtitle, displayRequiredText, requiredText }) => {
    const { viewOnly, submissionState, formMethods, printableView } = useContext(DataContext)
    const formWidth = 'full'
    const backgroundColorClass = ''
    
    const fields = useMemo(() => {
        return uploadFieldParser(
            config,
            formWidth,
            null,
            backgroundColorClass,
            uploadInputSelector,
            viewOnly,
            printableView,
            submissionState
        )
    }, [config, viewOnly, submissionState, formMethods, printableView])

    return (
        <SectionWrapper title={title} subtitle={subtitle} displayRequiredText={displayRequiredText} requiredText={requiredText}>
            <div className="formWrapper">{fields}</div>
        </SectionWrapper>
    )
}
