import React, { useContext } from 'react'
import { BiMapPin } from 'react-icons/bi'
import { PanelStyleContext } from '../../../../elem/panel/PanelStyleContext'
import { MapControlContext } from '../MapControlContext'
import DataContextProvider from './DataContext'
import SearchForm from './SearchForm'

const SearchByAddress = () => {
    const { displaySearchByAddress } = useContext(MapControlContext)

    const { borderClass } = useContext(PanelStyleContext)

    return (
        <DataContextProvider>
            {displaySearchByAddress ? (
                <div className="searchByAddressPositioning">
                    <div className="searchByAddressPadding">
                        <div className={`searchByAddressWrapper ${borderClass}`}>
                            <div className="searchByAddressHeader">
                                <div className="searchByAddressTitle">
                                    <BiMapPin className="mapLayerIcon" />
                                    <span className="searchByAddressText">
                                        Search by Address
                                    </span>
                                </div>
                            </div>
                            <hr
                                className={`${borderClass} searchByAddressDivider`}
                            />
                            <div className="searchByAddressContent py-2">
                                <div className="searchByAddressText is-size-7 has-text-centered">
                                    Select the features around the address
                                </div>
                                <div className="searchByAddressBar">
                                    <SearchForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </DataContextProvider>
    )
}

export default SearchByAddress
