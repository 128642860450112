export default {
    'project': {
        project: null,
        projectAlias: null,
        address: null,
        projectFacility: []
    },
    'facility': {
        agent: null,
        aquifer: [],
        capacity: null,
        construct: null,
        constructCasing: [],
        constructPerforation: [],
        facility: null,
        facilityAgent: null,
        facilityAlias: null,
        lithology: [],
        pump: null,
        stratigraphy: [],
        waterLevel: []
    },
    result: {
        facility: null,
        samples: [],
        results: []
    }
}

export const resolveUploadStructure = (submission, formType) => {
    if (formType && formType.APILink && formType.APILink === 'project') {
        if (submission.project) {
            if (!submission.projectFacility || (Array.isArray(submission.projectFacility) && submission.projectFacility.length <= 0 )) {
                if (submission.project.projectFacility && Array.isArray(submission.project.projectFacility)) {
                    submission.projectFacility = submission.project.projectFacility 
                }
            }
            if (!submission.projectAlias) {
                if(submission.project.projectAlias)
                submission.projectAlias = submission.project.projectAlias
            }   
        }
    }
    return submission
}
