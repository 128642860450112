import React from 'react'

export default ({ resetFunction }) => {
    return (
        <div
            className="panelButton timeWindowOptionWrapper"
            onClick={() => resetFunction()}
        >
            <span className="timeWindowOptionText">Reset Zoom</span>
        </div>
    )
}