import React, { useContext, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import ReactSelect from 'react-select'
import get from 'lodash.get'
import { FaAsterisk } from 'react-icons/fa'

import SelectStyles from '../../../elem/form/SelectStyles'
import { DataContext } from '../DataContext'
import { ExistingDataContext } from './ExistingSubmissionDataContext'
import {
    convertStringToBool,
    getApiErrorFromErrorState,
    getWarningFromErrorState,
    isChanged,
} from '../../../../utils/submissions/values'
import { Tooltip } from '../../../elem/Tooltip'
import FieldErrors from './FieldErrors'

const mapValuesToOptions = (values, fieldAccessorMap) => {
    if (!values) {
        return []
    }
    const options = values
        .filter(v => v[fieldAccessorMap.value] !== '')
        .map(v => {
            return {
                label: v[fieldAccessorMap.label],
                value:
                    fieldAccessorMap.type === 'number'
                        ? parseFloat(v[fieldAccessorMap.value])
                        : convertStringToBool(v[fieldAccessorMap.value]),
                type: fieldAccessorMap.type,
                active: v[fieldAccessorMap.active],
            }
        })
    return options
}

const onChange = ([selected]) => {
    // React Select return object instead of value for selection
    return { value: selected }
}

const Select = ({
    name,
    fieldName,
    values,
    control,
    helper,
    errors,
    fieldAccessorMap,
    formWidth,
    dataAccessor,
    displayLabel,
    rowIdx,
    required,
    registerParams,
    columnWidth,
    disabled,
    watch,
    printable = false,
}) => {
    const { submissionState, activePanel, viewOnly, errorState } = useContext(
        DataContext
    )
    const c = useContext(ExistingDataContext)
    const uploadChanges = c ? c.uploadChanges : null

    const options = useMemo(() => {
        return mapValuesToOptions(values, fieldAccessorMap)
    }, [values, fieldAccessorMap, submissionState])

    const changed = useMemo(
        () =>
            isChanged(
                uploadChanges,
                dataAccessor,
                name,
                rowIdx,
                submissionState
            ),
        [uploadChanges, submissionState, dataAccessor, name, rowIdx]
    )

    const inputName = `${dataAccessor}${
        typeof rowIdx !== 'undefined' ? `[${rowIdx}]` : ''
    }.${name}Select`

    const formError =
        errors && get(errors, inputName) ? get(errors, inputName) : null

    const warning = useMemo(() => {
        return getWarningFromErrorState(errorState, name, rowIdx, dataAccessor)
    }, [errorState, name, activePanel, rowIdx, formError, dataAccessor])

    const apiError = useMemo(() => {
        return getApiErrorFromErrorState(
            errorState,
            name,
            rowIdx,
            dataAccessor,
            formError
        )
    }, [errorState, name, activePanel, rowIdx, formError, dataAccessor])

    return (
        <div
            className={`column ${
                formWidth === 'full' ? 'is-one-third' : 'is-full'
            } no-vertical-padding formInputWrapper`}
        >
            <div className="formInputLayout">
                <div className="field is-horizontal">
                    {displayLabel && (
                        <div className="field-label is-small">
                            <label className="label">
                                <span>{fieldName}</span>
                                {required && !printable ? (
                                    <span className="requiredStar has-text-danger">
                                        {<FaAsterisk />}
                                    </span>
                                ) : null}{' '}
                            </label>
                        </div>
                    )}
                    <div className="field-body">
                        <div className={`field ${helper ? 'has-addons' : ''}`}>
                            {!printable && (
                                <div
                                    className={`control is-expanded`}
                                    data-tip={`${
                                        watch(`${inputName}`)
                                            ? watch(`${inputName}`).label
                                            : ''
                                    }`}
                                    data-for={inputName}
                                >
                                    <Controller
                                        as={
                                            <ReactSelect
                                                className={`select is-multiple is-small is-multiple is-fullwidth reactSelect ${
                                                    changed
                                                        ? 'changedSelect'
                                                        : ''
                                                }`}
                                                classNamePrefix="reactSelect"
                                                options={options}
                                                styles={SelectStyles}
                                                width={
                                                    columnWidth
                                                        ? `${columnWidth}px`
                                                        : null
                                                }
                                                isOptionDisabled={option =>
                                                    !option.active
                                                }
                                                menuPlacement="auto"
                                                isClearable
                                                menuPortalTarget={document.body}
                                                isDisabled={viewOnly || disabled}
                                            />
                                        }
                                        name={inputName}
                                        control={control}
                                        onChange={onChange}
                                        rules={registerParams}
                                    />
                                    <Tooltip
                                        id={inputName}
                                        extraClass={`tooltip-2`}
                                    />
                                    <FieldErrors
                                        formError={formError}
                                        warning={warning}
                                        apiError={apiError}
                                    />
                                </div>
                            )}
                            {printable && (
                                <div
                                    className={`control is-expanded`}
                                >
                                    <Controller
                                        as={
                                            <ReactSelect
                                                className={`select is-multiple is-small is-multiple is-fullwidth reactSelect is-printable`}
                                                classNamePrefix="reactSelect"
                                                options={options}
                                                styles={SelectStyles}
                                                width={
                                                    columnWidth
                                                        ? `${columnWidth}px`
                                                        : null
                                                }
                                                isOptionDisabled={option =>
                                                    !option.active
                                                }
                                                menuPlacement="auto"
                                                isDisabled={true}
                                            />
                                        }
                                        name={inputName}
                                        control={control}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Select
