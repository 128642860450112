import React, { useContext } from 'react'
import Draggable from 'react-draggable'

import DataContextProvider from './DataContext'
import { AppStateContext } from '../../AppStateContext'
import Panel from '../../../../elem/panel/Panel'
import { waterLevelPanel as panelClassConfig } from '../../../../../utils/styles/panelStyles'
import HydrographHeader from './HydrographHeader'
import HydrographChart from './HydrographChart'
import HydrographFooter from './HydrographFooter'

const Hydrograph = ({ width }) => {
    const { detailState } = useContext(AppStateContext)
    const visible = detailState.hydrograph.visible
    return (
        <Draggable bounds="parent" handle=".hydrographChartPanelHeader" positionOffset={{x: '.75rem', y: '.75rem'}} >
            <div className={`column is-6-desktop is-half-tablet is-full-mobile hydrographChartPanelWrapper ${!visible ? 'is-invisible' : '' }`}>
                <Panel panelClassConfig={panelClassConfig(width)}>
                    <div className="explorerWrapper">
                        <DataContextProvider>
                            <HydrographHeader />
                            <HydrographChart />
                            <HydrographFooter />
                        </DataContextProvider>
                    </div>
                </Panel>
            </div>
        </Draggable>
    )
}

export default Hydrograph