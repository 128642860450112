const submitterRegex = new RegExp('Submitter_([A-Za-z]+)')

const getRoles = user => {
    if (user && user.profile && user.profile.role) {
        const roles = user.profile.role
        if (Array.isArray(roles)) {
            return roles.join(',')
        } else {
            return roles
        }
    } else {
        return ''
    }
}

const checkRole = (role, roles) => {
    if (roles && role) {
        return roles.includes(role)
    } else {
        return false
    }
}

const getAgencyCode = (roles) => {
    if (roles && isSubmissionRole(roles)) {
        const submitterRole = roles.match(submitterRegex)
        if (submitterRole && submitterRole.length === 2) {
            return submitterRole[1]
        }
        return submitterRole
    } else {
        return null
    }
}

const isSubmissionRole = roles => {
        if (roles) {
            return !!roles.includes('Submitter')
        }
        return false
}

const isReviewerRole = roles => {
    if (roles) {
        return !!roles.includes('Form_Reviewer')
    }
    return false
}

const hasPrintAccess = roles => {
    if (roles) {
        return !!roles.includes('Print')
    }
    return false
}

const isPrinter = roles => { // a user who ONLY has print access
    if(roles) {
        return hasPrintAccess(roles) && !hasAccessToAdmin(roles) && !isSubmissionRole(roles) && !isReviewerRole(roles)
    }
    return false
}

const hasAccessToManageSampleResults = roles => 
    checkRole('Industry', roles) ||
    checkRole('DataEntry', roles) ||
    checkRole('Form_Reviewer', roles) ||
    checkRole('Print', roles) ||
    isSubmissionRole(roles)

const hasAccessToAdmin = roles => checkRole('Administrator', roles)

const hasAccessToAgency = roles => checkRole('Agency', roles)

const hasAccessToPWS = roles => checkRole("PWSAccess", roles)

export {
    getRoles,
    checkRole,
    hasAccessToManageSampleResults,
    hasAccessToAdmin,
    hasAccessToAgency,
    getAgencyCode,
    isReviewerRole,
    hasAccessToPWS,
    hasPrintAccess,
    isPrinter
}
