import React, { useContext } from 'react'

import PanelHeader from '../../../../elem/panel/PanelHeader'
import { DataContext } from './DataContext'
import ResetZoomButton from '../../../../elem/chart/ResetZoomButton'
import { IconContext } from 'react-icons'
import { IconTooltip as Tooltip } from '../../../../elem/Tooltip'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'

export default () => {
    const {
        resetZoom,
        isCollapsed,
        toggleCollapsed
    } = useContext(DataContext)

    return (
        <PanelHeader extraClass={'aggregatePanelHeader'}>
            <div className="panelHeaderText">Water Level Chart</div>
            <div className="panelButtons">
                <ResetZoomButton resetFunction={resetZoom} />
                <div
                    className={`panelHeaderIconDiv`}
                    data-tip={`Collapse / Expand`}
                    data-for={`displayCollapse`}
                    onClick={ () => toggleCollapsed() }
                >
                    <IconContext.Provider
                        value={{
                            className: `panelHeaderIcon ${
                                isCollapsed ? 'active' : 'inactive'
                            }`,
                        }}
                    >
                        {isCollapsed ? (
                            <IoIosArrowUp />
                        ) : (
                            <IoIosArrowDown />
                        )}
                    </IconContext.Provider>
                    <Tooltip id={'displayCollapse'} />
                </div>
            </div>
        </PanelHeader>
    )
}
