import React from 'react'

export default ({ filterExpanded, toggleFilter, message='refine filter results', subject='No results found. Please'}) =>
    !filterExpanded && (
        <div className="noDataComponentWrapper">
            <div className="noDataComponent">
                <span className="noDataText">{subject}</span>
                <span
                    onClick={() => toggleFilter(true)}
                    className="noDataResetFilterText"
                >
                    {message}
                </span>
            </div>
        </div>
    )
