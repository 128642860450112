import React from "react"
import { FaAsterisk } from "react-icons/fa"

export default () => (
    <div className="chartFooter is-size-7 has-background-white-ter">
        <p>
            <span>
                <FaAsterisk className="pb-3"/>
            </span>
            <span>
                Days without at least 5 years of water level data are not included in statistics calculations.
            </span>
        </p>
    </div>
)