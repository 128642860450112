import React, { useContext } from 'react'

import { formatValue } from '../../../../utils/table/parseColumns'
import withConfig from '../../../wrappers/withConfig'
import { AppStateContext } from '../AppStateContext'
import { DataContext } from './DataContext'

const HydrographLink = ({idColumn, data, updateDetailState}) => {
    const displayHydrographField = data && data['DisplayHydrograph']
    const idValue = data && data[idColumn]
    const noDataMessage = 'This site does not contain enough water level data to be statistically significant.'

    return (
        <div key={`hydrograph-field`} className="field is-horizontal">
            <div className="label is-size-7">Hydrograph Statistics:</div>
            <div
                className="value margin-left-sm is-size-7"
            >
        {displayHydrographField ? 
                    <div className="descriptionCell linkCell">
                        <span
                            className=""
                            onClick={e => {
                                updateDetailState('hydrograph', {
                                    visible: true,
                                    facilityID: idValue,
                                })
                            }}
                        >
                            View
                        </span>
                    </div>
                : 
                <div className="descriptionCell">
                    <span className="">{noDataMessage}</span>    
                </div>
            }
            </div>
        </div>
    )
}

const OverviewDetails = ({ config, name, displayName }) => {
    const { detailData, filterData } = useContext(DataContext)
    const { ID_COLUMN } = config
    const { updateDetailState } = useContext(AppStateContext)
    if (!(detailData && detailData[name] && filterData && filterData[name])) {
        return null
    }
    const formFields = filterData[name]
    const data = detailData[name]
    const fieldsToDisplay = Object.keys(data).filter(
        x =>
            // filter out those fields that are not supposed to be displayed
            // in the table
            !!formFields.find(
                field => field.DisplayInTable && field.ColumnName === x
            )
    )

    const displayFields = formFields.filter(x =>
        fieldsToDisplay.includes(x.ColumnName)
    )
    return (
        <div className="column is-half-desktop is-half-tablet is-full-mobile detailsDataWrapper">
            <div className="detailsDataHeader">{displayName} Overview</div>
            <div className="detailsData">
                {displayFields.map((field, idx) => {
                    const fieldLabel = field.Prompt
                    const fieldColumn = field.ColumnName
                    const fieldValue = data[fieldColumn]
                        ? data[fieldColumn]
                        : 'None'
                    return (
                        <div
                            key={`field-${idx}`}
                            className="field is-horizontal"
                        >
                            <div className="label is-size-7">{fieldLabel}</div>
                            <div
                                className="value margin-left-sm is-size-7"
                                dangerouslySetInnerHTML={{
                                    __html: formatValue(fieldValue),
                                }}
                            />
                        </div>
                    )
                })}
                {name === 'waterLevelOverview' && <HydrographLink idColumn={ID_COLUMN} data={data} updateDetailState={updateDetailState} />}
            </div>
        </div>
    )
}

export default withConfig(OverviewDetails)
