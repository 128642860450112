const addressUnits = [
    {
        label: 'Feet',
        value: 'feet'
    },
    {
        label: 'Meters',
        value: 'meters'
    },
    {
        label: 'Miles',
        value: 'miles'
    },
    {
        label: 'Kilometers',
        value: 'kilometers'
    }
]

export { addressUnits }