import React, { useContext } from 'react'

import PanelHeader, { close } from '../../../../elem/panel/ModalPanelHeader'
import { DataContext } from './DataContext'
import ResetZoomButton from '../../../../elem/chart/ResetZoomButton'
import { AppStateContext } from '../../AppStateContext'

export default () => {
    const { updateDetailState } = useContext(AppStateContext)
    const {
        resetZoom
    } = useContext(DataContext)

    const onClose = close.bind(this, updateDetailState, 'hydrograph')

    return (
        <PanelHeader headerClass={'hydrographChartPanelHeader'} title={'Hydrograph Statistics'} onClose={onClose}>
            <ResetZoomButton resetFunction={resetZoom} />
        </PanelHeader>
    )
}
