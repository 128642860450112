export default {
    'facility': [
        {
            value: 'FacilityName',
            label: 'GSA ID',
            type: 'String',
        },
        {
            value: 'Alias',
            label: 'Alias',
            type: 'String'
        }
    ],
    'project': [
        {
            value: 'ProjectName',
            label: 'Project Name',
            type: 'String'
        },
        {
            value: 'Alias',
            label: 'Project Alias',
            type: 'String'
        }
    ],    
    'result': [
        {
            value: 'FacilityName',
            label: 'GSA ID',
            type: 'String',
        },
        {
            value: 'Alias',
            label: 'Alias',
            type: 'String'
        }
    ],
}