import React, { createContext, useState, useEffect, useContext } from 'react'
import { getLayerTypes, getControls } from './mapConfig'
import { getLayerState } from './layers/getLayer'
import { DataContext } from './DataContext'

const MapControlContext = createContext(null)

const MapControlContextProvider = ({ children }) => {
    const { layerData } = useContext(DataContext)
    const [layerTypes] = useState(getLayerTypes())
    const [displayMapLayers, toggleMapLayers] = useState(false)
    const [displaySearchByAddress, toggleDisplaySearchByAddress] = useState(false)
    const [controls, setControls] = useState(getControls(layerTypes))
    const [layerState, setLayerState] = useState(getLayerState(layerData))

    const [activeControl, setActiveControl] = useState(null)
    const [displayTooltip, toggleTooltip] = useState(false)
    const [isCollapsed, setCollapsed] = useState(false)

    const clearControls = () => {
        const newControls = Object.keys(controls).reduce((acc, controlName) => {
            const control = controls[controlName]
            control.active = false
            return {
                ...acc,
                [controlName]: control,
            }
        }, {})
        setControls(newControls)
        setActiveControl(null)
    }

    const activateControl = name => {
        clearControls()
        const newControls = Object.keys(controls).reduce((acc, controlName) => {
            const control = controls[controlName]
            if (controlName === name) {
                control.active = true
            }
            return {
                ...acc,
                [controlName]: control,
            }
        }, {})
        setControls(newControls)
        setActiveControl(name)
    }

    const toggleCollapsed = () => {
        setCollapsed(!isCollapsed)
    }

    // update layer state when layers change
    useEffect(() => {
        setLayerState(prevLayerState => getLayerState(layerData, prevLayerState))
    }, [layerData])

    return (
        <MapControlContext.Provider
            value={{
                controls,
                activeControl,
                activateControl,
                clearControls,
                displayTooltip,
                toggleTooltip,
                displayMapLayers,
                displaySearchByAddress,
                toggleDisplaySearchByAddress,
                toggleMapLayers,
                layerState,
                setLayerState,
                isCollapsed,
                setCollapsed,
                toggleCollapsed
            }}
        >
            {children}
        </MapControlContext.Provider>
    )
}

export { MapControlContext }
export default MapControlContextProvider
