import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import clearFormFields from '../../../../../utils/form/clearFormFields'
import getFilterParamsFromForm from '../../../../../utils/form/getFilterParamsFromForm'
import formFieldParser, { formValidate, formInputSelector } from '../../../../../utils/form/formFieldParser'

import { FacilityAssignmentContext } from './DataContext'
import FormResultsSummary from '../../../../elem/form/FormResultsSummary'
import deepEqual from 'deep-equal'

const FacilityFilter = () => {
    const { projectFacilityFilterConfig, params, setParams, setHighlightedPossibleFacilities, fetchResultsCount, filterResultsCount, filterResultsLoading } = useContext(FacilityAssignmentContext)
    const formMethods = useForm({ mode: 'onChange' })
    const {
        handleSubmit,
        getValues,
        setValue,
        triggerValidation,
        reset,
        register,
        unregister,
        control,
        setError,
        clearError,
        watch, 
        errors,
    } = formMethods

    const fields = useMemo(() => projectFacilityFilterConfig ? formFieldParser(
        projectFacilityFilterConfig,
        register,
        unregister,
        control,
        getValues,
        setValue,
        setError,
        clearError,
        errors,
        'full',
        'possibleFacilities',
        '',
        watch,
        formValidate,
        formInputSelector
    ) : null, [projectFacilityFilterConfig])


    const w = watch({nest: true})

    const watchRef = useRef(w)

    useEffect(() => {
        if (!deepEqual(watchRef.current, w)) {
            fetchResultsCount(null, w)
            watchRef.current = w
        }
    }, [w])
    // when we submit the form, set the filter parameters
    const onSubmit = data => {
        const newParams = {
            PageSize: 50,
            Page: 1,
            SortByField: params.SortByField,
            SortOrder: params.SortOrder,
            ...getFilterParamsFromForm(data),
        }
        setHighlightedPossibleFacilities([])
        setParams(newParams, 'possibleFacilities')
    }

    // when we reset the form , clear form fields and fetch table data
    const onReset = (getValues, setValue) => {
        clearFormFields(getValues, setValue)
        const newParams = {
            PageSize: 50,
            Page: 1,
            SortByField: params.SortByField,
            SortOrder: params.SortOrder,
            new: Math.random()
        }
        setHighlightedPossibleFacilities([])
        setParams(newParams, 'possibleFacilities')
    }

    // see if the form has errors
    const formHasErrors = errors => {
        const hasErrors = Object.keys(errors)
            .map(errorKey => {
                const fieldErrors = errors[errorKey]
                if (typeof fieldErrors == 'object' && fieldErrors.length) {
                    if (fieldErrors.find(x => typeof x !== 'undefined')) {
                        return true
                    } else {
                        return false
                    }
                }
                return true
            })
            .find(x => x === true)
        return hasErrors
    }

    return (
        <div className="column is-12">
            <div className="is-size-4 has-text-centered matchingFacilityText">
                <span className="has-text-weight-semibold">
                    {`Filter Sites`}
                </span>
            </div>
            <FormResultsSummary 
                filterResultsCount={filterResultsCount}
                filterResultsLoading={filterResultsLoading}
                filterExpanded={true}
            />
            <form className="columns is-multiline form" onSubmit={handleSubmit(onSubmit)}>
                {fields}
                <div className="field is-horizontal is-grouped is-grouped-centered formSubmitWrapper">
                    <div className="control is-half">
                        <button
                            className={`button is-primary is-small formSubmitButton`}
                            type="submit"
                            onClick={e => {
                                e.preventDefault()
                                triggerValidation().then(() => {
                                    if (!formHasErrors(errors)) {
                                        handleSubmit(onSubmit)()
                                    }
                                })
                            }}
                        >
                            Apply Filter
                        </button>
                    </div>
                    <div className="control is-half">
                        <button
                            className={`button is-secondary is-small formSubmitButton`}
                            type="reset"
                            onClick={() => { 
                                onReset(getValues, setValue)
                                reset()
                                handleSubmit(onSubmit)
                            }}
                        >
                            Clear All Filters
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default FacilityFilter
