const canNavigate = (group, submissionState) => {
    if (group.requires) {
        if (group.type === 'component') {
            return true
        }
        if (group.type === 'array') {
            if (submissionState && submissionState[group.requires] && submissionState[group.requires].length) {
                return true
            } else {
                return false
            }
        }
        if (group.type === 'object') {
            if (submissionState && submissionState[group.requires] && Object.values(submissionState[group.requires]).some(x => !!x)) {
                return true
            } else {
                return false
            }
        }
    } else {
        return true
    }
}

export { canNavigate }