import React, { useContext } from 'react'
import AsyncSelect from 'react-select/async'
import customStyles from '../../../../elem/form/SelectStyles'

import { DataContext } from './DataContext'
const SearchBar = () => {
    const { setAddress, selectedAddress, setSelectedAddress, fetchCandidateAddresses } = useContext(DataContext)
    return (
        <div className="field is-fullwidth">
            <div className="control is-expanded">
                <AsyncSelect
                    className="select is-small searchBar is-fullwidth"
                    cacheOptions
                    loadOptions={fetchCandidateAddresses}
                    defaultOptions
                    isClearable
                    styles={customStyles}
                    classNamePrefix="searchBar"
                    defaultValue={selectedAddress}
                    onInputChange={value => setAddress(value)}
                    onChange={(option) => setSelectedAddress(option)}
                    placeholder={'Enter an address...'}
                />
            </div>
        </div>
    )
}

export default SearchBar