import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useRef,
    useCallback
} from 'react'
import deepEqual from 'deep-equal'

import { APIRequestContext } from '../../../../wrappers/APIRequestContext'
import withConfig from '../../../../wrappers/withConfig'
import actions from '../../../../../utils/submissions/actions'
import toast from '../../../../elem/Toast'

import { getQueryStringFromParams } from '../../../../../utils/params'
import { DataContext } from '../../DataContext'
import { debounce } from 'debounce'
import getFilterParamsFromForm from '../../../../../utils/form/getFilterParamsFromForm'

const FacilityAssignmentContext = createContext(null)

const FacilityAssignmentContextProvider = ({ config, children }) => {
    const [loadingPossibleFacilities, setLoadingPossibleFacilities] = useState(false)
    const [loadingAssignedFacilities, setLoadingAssignedFacilities] = useState(false)
    const [loadingConfig, setLoadingConfig] = useState(true)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { submissionState } = useContext(DataContext)
    const [projectFacilityFilterConfig, setProjectFacilityFilterConfig] = useState(null)
    const [projectFacilityListConfig, setProjectFacilityListConfig] = useState(null)
    const [assignedFacilityData, setAssignedFacilityData] = useState([])
    const [assignedFacilityMetadata, setAssignedFacilityMetadata] = useState([])
    const [possibleFacilityData, setPossibleFacilityData] = useState([])
    const [possibleFacilityMetadata, setPossibleFacilityMetadata] = useState({})
    const [highlightedPossibleFacilities, setHighlightedPossibleFacilities] = useState([])
    const [highlightedAssignedFacilities, setHighlightedAssignedFacilities] = useState([])
    const [filterResultsCount, setFilterResultsCount] = useState()
    const [filterResultsLoading, setFilterResultsLoading] = useState()
    const projectFacilityAccessor = `projectFacility`

    const [params, setParams] = useState({
        'possibleFacilities': {
            Page: 1,
            PageSize: 50
        },
        'assignedFacilities':
        {
            PageSize: 50
        }
    })
    const possibleFacilityParams = useRef({})
    const assignedFacilityParams = useRef({})
    const assignedFacilityState = useRef([])
    const [possibleFacilityAbortController, setPossibleFacilityAbortController] = useState(new AbortController())
    const [assignedFacilityAbortController, setAssignedFacilityAbortController] = useState(new AbortController())
    const [filterResultsAbortController, setFilterResultsAbortController] = useState(new AbortController())
    const { API_URL, ID_COLUMN } = config
    
    const updateProjectFacilityConfig = (data) => {
        setProjectFacilityListConfig(data.list)
        setProjectFacilityFilterConfig(data.filter)
        setLoadingConfig(false)
    }

    useEffect(() => {
        setLoadingConfig(true)
        actions.getProjectFacilityConfig(
            authenticatedFetch,
            API_URL,
            updateProjectFacilityConfig
        )
    }, [])

    const fetchAssignedFacilities = useCallback(() => {
        setLoadingAssignedFacilities(true)
        const abort = new AbortController()
        setAssignedFacilityAbortController(abort)
        // const paramString = getQueryStringFromParams(params)
        authenticatedFetch(`${API_URL}/upload/project/assignedFacilities?uploadID=${submissionState.uploadId}`, { signal: abort.signal })
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setAssignedFacilityData(response.data)
                setAssignedFacilityMetadata(response.meta)
                setLoadingAssignedFacilities(false)
            })
            .catch(e => {
                if (e.name !== 'AbortError') {
                    toast({
                        level: 'error',
                        message:
                            'Assigned Sites List: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                    setLoadingAssignedFacilities(false)
                }
            })
    }, [assignedFacilityData, submissionState])

    const fetchPossibleFacilities = useCallback(params => {
        const assignedFacilityIds = assignedFacilityData.map(x => x[ID_COLUMN]).join(',')
        setLoadingPossibleFacilities(true)
        const abort = new AbortController()
        setPossibleFacilityAbortController(abort)
        const paramString = getQueryStringFromParams(params)
        authenticatedFetch(`${API_URL}/upload/project/possibleFacilities?${paramString}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            signal: abort.signal,
            body: JSON.stringify({
                assignedFacilityIds: assignedFacilityIds
            }),
        })
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setPossibleFacilityData(response.data)
                setPossibleFacilityMetadata(response.meta)
                setLoadingPossibleFacilities(false)
            })
            .catch(e => {
                if (e.name !== 'AbortError') {
                    toast({
                        level: 'error',
                        message:
                            'Possible Sites List: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                    setLoadingPossibleFacilities(false)
                }
            })
    }, [assignedFacilityData, ID_COLUMN])

    // fetch possible facility data on parameter or assigned facility data changes
    useEffect(() => {
        const hasNewAssignedFacilities = !deepEqual(assignedFacilityData.map(x => x[ID_COLUMN]).sort(), assignedFacilityState.current.sort())
        if (params && params.possibleFacilities) {
            const associatedParams = params.possibleFacilities
            if (submissionState && submissionState.uploadId) {
                if (!deepEqual(possibleFacilityParams.current, associatedParams) || hasNewAssignedFacilities) {
                    possibleFacilityAbortController.abort()
                    fetchPossibleFacilities(params)
                    possibleFacilityParams.current = associatedParams
                }
            }
        }
    }, [params, assignedFacilityData, possibleFacilityAbortController, submissionState])

    const fetchResultsDebounce = debounce((e, formData) => {
        // query for the updated count
        const newParams = {
            ...params, 
            possibleFacilities: {
                ...getFilterParamsFromForm(formData)
            }
        }
        const abort = new AbortController()
        setFilterResultsAbortController(abort)
        const paramString = getQueryStringFromParams(newParams)
        authenticatedFetch(`${API_URL}/upload/project/possibleFacilities/count?${paramString}`, {signal: abort.signal})
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                const { meta } = response
                const { count } = meta
                setFilterResultsCount(count)
                setFilterResultsLoading(false)
            })
            .catch(e => {
                if (e.name !== 'AbortError') {
                    toast({
                        level: 'error',
                        message:
                            'Sample List: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                    setFilterResultsLoading(false)
                }
            })
    }, 3000, false)

    const fetchResultsCount = useCallback((e, formData) => {
        filterResultsAbortController.abort()
        setFilterResultsLoading(true)
        fetchResultsDebounce.clear()

        fetchResultsDebounce(e, formData)
    }, [filterResultsAbortController])

    const updateParams = (newParams, field) => {
        setParams(previousParams => ({
            ...previousParams,
            [field]: {
                ...newParams
            }
        }))
    } 

    useEffect(() => {
        if (params && params.assignedFacilities) {
            const associatedParams = params.assignedFacilities
            if (submissionState  && submissionState.uploadId) {
                if (!deepEqual(assignedFacilityParams.current, associatedParams)) {
                    assignedFacilityAbortController.abort()
                    fetchAssignedFacilities(params)
                    assignedFacilityParams.current = associatedParams
                }
            }
        }
    }, [submissionState, params])

    useEffect(() => {
        const initialAssignedFacilities = submissionState[projectFacilityAccessor]
        ? submissionState[projectFacilityAccessor]
        : null

        if (initialAssignedFacilities) {
            if (!deepEqual(initialAssignedFacilities.map(x => x['facilityId']).sort(), assignedFacilityState.current.sort())){
                assignedFacilityAbortController.abort()
                fetchAssignedFacilities()
            }
        }
    }, [submissionState, ID_COLUMN, projectFacilityAccessor])

    useEffect(() => {
        const updatedFacilityState = assignedFacilityData.map(x => x[ID_COLUMN])
        assignedFacilityState.current = updatedFacilityState
    }, [assignedFacilityData])

    return (
        <FacilityAssignmentContext.Provider
            value={{
                loadingPossibleFacilities,
                loadingAssignedFacilities,
                loadingConfig,
                projectFacilityFilterConfig,
                projectFacilityListConfig,
                params,
                setParams: updateParams,
                possibleFacilityData,
                possibleFacilityMetadata,
                setPossibleFacilityData,
                assignedFacilityData,
                assignedFacilityMetadata,
                setAssignedFacilityData,
                projectFacilityAccessor,
                highlightedAssignedFacilities,
                setHighlightedAssignedFacilities,
                highlightedPossibleFacilities,
                setHighlightedPossibleFacilities,
                fetchResultsCount,
                filterResultsCount,
                filterResultsLoading
            }}
        >
            {children}
        </FacilityAssignmentContext.Provider>
    )
}

export { FacilityAssignmentContext }
export default withConfig(FacilityAssignmentContextProvider)
