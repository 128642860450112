import React from 'react'

import ExplorerMap from './ExplorerMap'
import DataContextProvider from './DataContext'
import MapControlContextProvider from './MapControlContext'
import ExplorerMapHeader from './ExplorerMapHeader'
import MapLayerControls from './layers/MapLayerControls'
import Panel from '../../../elem/panel/Panel'
import { mapPanel as panelClassConfig } from '../../../../utils/styles/panelStyles'
import SearchByAddress from './address-search/SearchByAddress'

const Map = ({ width }) => {
    return (
        <DataContextProvider>
            <MapControlContextProvider>
                <Panel panelClassConfig={panelClassConfig(width)}>
                    <ExplorerMapHeader />
                    <SearchByAddress />
                    <MapLayerControls />
                    <ExplorerMap />
                </Panel>
            </MapControlContextProvider>
        </DataContextProvider>
    )
}

export default Map