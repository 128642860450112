import React, { useContext } from 'react'

import DetailTabs from './DetailTabs'
import WellDetails from './WellDetails'
import ConstructionDetails from './ConstructionDetailList'
import SampleDetails from './SampleDetailList'
import WaterLevelDetails from './WaterLevelDetailList'
import DetailChart from './chart/DetailChart'
import { DataContext } from './DataContext'
import Spinner from '../../../elem/Spinner'

export default () => {
    const { isLoading } = useContext(DataContext)
    if (isLoading) {
        return <Spinner extraClass={'detailSpinner'} />
    }
    return (
        <>
            <DetailChart />
            <DetailTabs />
            <WellDetails />
            <ConstructionDetails />
            <WaterLevelDetails />
            <SampleDetails />
        </>
    )
}
