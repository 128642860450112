import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo,
} from 'react'

import toast from '../../../elem/Toast'
import withConfig from '../../../wrappers/withConfig'
import { AppStateContext } from '../AppStateContext'
import { timeWindowOptions } from '../../../../utils/chart/timeWindow'
import { ParameterContext } from '../../../wrappers/ParameterContext'
import { generateDateParams } from '../../../../utils/chart/values'
import { filterTimeData, getDataWithinTimePeriod } from '../../../../utils/chart/timeWindow'
import getChartConfig from '../../../../utils/chart/getChartConfig'


const DataContext = createContext(null)

const DataContextProvider = ({ config, children }) => {
    // get the detail id from the app state context
    const { detailState } = useContext(AppStateContext)
    const { params } = useContext(ParameterContext)
    const { well: wellDetailState } = detailState
    const facilityID = wellDetailState.facilityID

    // get the API_URL from config
    const { API_URL } = config

    // loading state
    const [isLoading, setLoading] = useState(true)

    // data state
    const [detailData, setDetailData] = useState({})
    const [filterData, setFilterData] = useState({})
    const [visibleDetailTab, setVisibleDetailTab] = useState('well')
    
    const [visibleChartTab, setVisibleChartTab] = useState('waterLevel')

    const {dateField: waterLevelDateField } = getChartConfig(config, 'WATER_LEVEL')

    // display state
    const [timeWindow, setTimeWindow] = useState(timeWindowOptions[0])
    const [displayTimeWindowDropdown, toggleTimeWindowDropdown] = useState(
        false
    )
    const [resetExpanded, toggleResetExpanded] = useState(false)
    const [selectedAnalytes, setSelectedAnalytes] = useState([])
    const [
        displayAnalyteWindowDropdown,
        toggleAnalyteWindowDropdown,
    ] = useState(false)

    const [selectedUnits, setSelectedUnits] = useState(null)

    const [zoomTrigger, setZoomTrigger] = useState(false)

    const analyteDateParams = useMemo(() => {
        const d = generateDateParams(params)
        return d.some(x => x !== null) ? d : null
    }, [params])

    const waterLevelDateParams = useMemo(() => {
        const d = generateDateParams(params, 'waterLevel', waterLevelDateField)
        return d.some(x => x !== null) ? d : null
    }, [params, waterLevelDateField])

    // fetch data on facilityID change
    const fetchData = () => {
        setLoading(true)
        if (facilityID) {
            setDetailData({})
            fetch(`${API_URL}/well/${facilityID}`)
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setDetailData(response.data)
                    setFilterData(response.filterData)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                            'Well Detail: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                })
                .finally(() => setLoading(false))
        }
    }

    useEffect(() => {
        fetchData(facilityID)
    }, [facilityID])

    const analyteChartData = useMemo(() => {
        if (detailData.analyteChart && detailData.analyteChart.length) {
            return analyteDateParams
                ? getDataWithinTimePeriod(
                      detailData.analyteChart,
                      analyteDateParams,
                      'SampleDate'
                  )
                : filterTimeData(
                      detailData.analyteChart,
                      timeWindow,
                      'SampleDate'
                  )
                .sort( (a, b) => {
                    let analyte1 = a.Analyte.toLowerCase()
                    let analyte2 = b.Analyte.toLowerCase()
                    return (analyte1 > analyte2) ? 1 : ((analyte2 > analyte1) ? -1 : 0)
                })
        }
        return []
    }, [detailData, timeWindow, analyteDateParams])

    const waterLevelChartData = useMemo(() => {
        if (detailData.waterLevelChart && detailData.waterLevelChart.length) {
            return waterLevelDateParams
            ? getDataWithinTimePeriod(
                  detailData.waterLevelChart,
                  waterLevelDateParams,
                  'MeasurementDate'
              ) :
                detailData.waterLevelChart
        }
        return []
    }, [detailData, waterLevelDateParams])

    const analyteList = useMemo(() => {
        if (analyteChartData && analyteChartData.length && detailData && detailData.analyteChartDropdown) {
            const activeAnalytes = detailData.analyteChartDropdown.filter((e, i) =>
                analyteChartData.map( ({Analyte, Units}) => ({Analyte, Units}) )
                    .findIndex(a => a.Analyte === e.Analyte && a.Units === e.Units) !== -1)
            return activeAnalytes
        }
        return []
    }, [analyteChartData, detailData])
    
    useEffect(() => {
        if (
            analyteList &&
            analyteList.length
        ) {
            const firstAnalyte = analyteList[0]
            setSelectedAnalytes([firstAnalyte])
        }
    }, [analyteList])
    
    const toggleSelectedAnalyte = useCallback(
        analyte => {
            if (selectedAnalytes.find(x => x.ParamID === analyte.ParamID && x.Units === analyte.Units)) {
                // there are some records with equal paramid but different unit 
                //{FacilityID: 103404, ParamID: 177, Units: 'degrees C', Analyte: 'TEMPERATURE'} //{FacilityID: 103404, ParamID: 177, Units: 'degrees F', Analyte: 'TEMPERATURE'}
                setSelectedAnalytes(
                    selectedAnalytes.filter(x => x.ParamID !== analyte.ParamID)
                )
            } else {
                if (selectedAnalytes.length < 5) {
                    const unit = analyte.Units
                    if (selectedUnits && unit !== selectedUnits) {
                        toast({
                            level: 'info',
                            message: `Please select an analyte that has the same units (${selectedUnits}) as the other analytes in the chart.`,
                            alert: true,
                        })
                    } else {
                        setSelectedAnalytes(selectedAnalytes.concat(analyte))
                    }
                } else {
                    toast({
                        level: 'info',
                        message:
                            'Too many analytes selected. Select up to 5 analytes to display.',
                        alert: true,
                    })
                }
            }
        },
        [selectedAnalytes, selectedUnits]
    )

    const resetAnalytes = useCallback(() => {
        const firstAnalyte = analyteList[0]
        if (firstAnalyte) {
            setSelectedAnalytes([firstAnalyte])
        } else {
            setSelectedAnalytes([])
        }
    }, [analyteList])

    useEffect(() => {
        if (selectedAnalytes.length) {
            setSelectedUnits(selectedAnalytes[0].Units)
        } else {
            setSelectedUnits(null)
        }
    }, [selectedAnalytes])

    const resetZoom = useCallback(() => setZoomTrigger(!zoomTrigger), [zoomTrigger])

    return (
        <DataContext.Provider
            value={{
                facilityID,
                isLoading,
                detailData,
                filterData,
                visibleDetailTab,
                setVisibleDetailTab,
                visibleChartTab,
                setVisibleChartTab,
                timeWindow,
                setTimeWindow,
                displayTimeWindowDropdown,
                toggleTimeWindowDropdown,
                selectedAnalytes,
                setSelectedAnalytes,
                toggleSelectedAnalyte,
                toggleAnalyteWindowDropdown,
                displayAnalyteWindowDropdown,
                selectedUnits,
                resetExpanded, 
                toggleResetExpanded,
                resetAnalytes,
                analyteChartData,
                analyteList,
                waterLevelChartData,
                zoomTrigger,
                resetZoom
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export { DataContext }
export default withConfig(DataContextProvider)
