import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react'

import withConfig from '../../../../wrappers/withConfig'
import { AppStateContext } from '../../AppStateContext'
import { ParameterContext } from '../../../../wrappers/ParameterContext'
import { APIRequestContext } from '../../../../wrappers/APIRequestContext'
// import filterTimeData, { timeWindowOptions } from '../../../../../utils/chart/timeWindow'
import toast from '../../../../elem/Toast'
import { generateDateParams } from '../../../../../utils/chart/values'
import getChartConfig from '../../../../../utils/chart/getChartConfig'

const DataContext = createContext(null)

// const parseChartData = (data) => {
//     return data
// }

export default withConfig(({ config, children }) => {
    const { detailState: { hydrograph: { facilityID } }} = useContext(AppStateContext)
    const { params } = useContext(ParameterContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [chartData, setChartData] = useState([])
    const [zoomTrigger, setZoomTrigger] = useState(false)

    
    const [loading, setLoading] = useState(false)
    const { 
        API_URL, 
        // ID_COLUMN 
    } = config
    const { dateField } = getChartConfig(config, 'WATER_LEVEL')
    const dateParams = useMemo(() => {
        const d = generateDateParams(params, 'waterLevel', `${dateField}`)
        return d.some(x => x !== null) ? d : null
    }, [params, dateField])


    const fetchChartData = useCallback((facilityID) => {
        return new Promise(async (resolve, reject) => {

            await authenticatedFetch(`${API_URL}/waterLevel/hydrograph/${facilityID}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers':
                        'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setChartData(response)
                    return resolve(response)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                        'Water Level Chart: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                        })
                    return reject()
                })
        })
    }, [])
     

    useEffect(() => {
        const fetchData = async facilityID => {
            if (!isNaN(facilityID) && facilityID != null) {
                try {
                    setLoading(true)
                    await fetchChartData(facilityID).finally(() => setLoading(false))
                } catch (e) {
                    console.log('error fetching data.')
                }
            }
        }
        fetchData(facilityID)
    }, [fetchChartData, facilityID])

    const hydrographChartData = useMemo(() => {
        return chartData
    }, [chartData, dateParams])

    const resetZoom = useCallback(() => {
        setZoomTrigger(!zoomTrigger)
    }, [zoomTrigger])

    return (
        <DataContext.Provider
            value={{
                loading,
                chartData,
                hydrographChartData,
                resetZoom,
                zoomTrigger
            }}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }
