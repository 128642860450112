import React, { useCallback, useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
// import { useAuth } from 'oidc-react'
import withConfig from '../../wrappers/withConfig'
import { UserContext } from '../../wrappers/UserContext'

dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(duration)

export default withConfig(({config}) => {
    // const { userData: user } = useAuth()
    const { client, user } = useContext(UserContext)
    const [ expiresAt, setExpiresAt ] = useState(null)
    const [timeRemaining, setTimeRemaining] = useState(config.INACTIVITY_TIME)
    const [displayTime, setDisplayTime] = useState(config.INACTIVITY_TIME)
    const [intervalEvent, setIntervalEvent] = useState(null)
    if (!user) {
        return null
    }

    useEffect(() => {
        // const d = dayjs.duration(dayjs(user.expires_at * 1000).diff(dayjs()))
        // console.log(`token expires in: ${d.hours().toString().padStart(2, "0")}h ${d.minutes().toString().padStart(2, "0")}m ${d.seconds().toString().padStart(2, "0")}s`)
        const now = dayjs().valueOf()
        const msToExpiry = config.INACTIVITY_TIME * 1000
        const expires = now + msToExpiry
        setExpiresAt(expires)

        window.addEventListener('click', e => {
            setExpiresAt(dayjs().valueOf() + config.INACTIVITY_TIME * 1000)
        }, {capture: true})
    }, [])

    const timer = useCallback(() => 
        setInterval(() => {
            const now = dayjs().valueOf()
            setTimeRemaining(expiresAt - now)
            const deltaT = dayjs.duration(expiresAt - now, 'milliseconds')
            let hours = deltaT.hours()
            let mins = deltaT.minutes()
            let seconds = deltaT.seconds()
            setDisplayTime(`${hours.toString().padStart(2, "0")}h ${mins.toString().padStart(2, "0")}m ${seconds.toString().padStart(2, "0")}s`)
        }, 1000)
    , [expiresAt])

    useEffect(() => {
        clearInterval(intervalEvent)
        // set an interval to update the countdown
        if (timeRemaining >= 0) {
            const i = timer()
            setIntervalEvent(i)
            // if(user.expires_at < dayjs().unix()) {
            //     // client.signoutRedirect()

            //     client.signinSilent()
            //         .then((user) => {
            //             console.log(user)  
            //         })
            //     .catch(e => console.log(e))
            // }   
        } else {
            client.signoutRedirect()
        }
    }, [timeRemaining, expiresAt, timer])

    return (
        <div className="navbar-item">
            Session Expires in {displayTime}
        </div>
    )
})