import React, {
    createContext,
    useContext,
    useState,
    useCallback,
} from 'react'

import withConfig from '../../../../wrappers/withConfig'
import { addressUnits } from '../../../../../utils/constants/units'
import { APIRequestContext } from '../../../../wrappers/APIRequestContext'
import toast from '../../../../elem/Toast'

const DataContext = createContext(null)

const DataContextProvider = ({ config, children }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [centerLatLong, setCenterLatLong] = useState([])
    const [address, setAddress] = useState('1307')
    const [selectedAddress, setSelectedAddress] = useState(null)
    const [addressList, setAddressList] = useState([])
    const [units, setUnits] = useState(addressUnits[0])
    const [searchRadius, setSearchRadius] = useState(5280)
    const [dataAbortController, setDataAbortController] = useState(
        new AbortController()
    )
    const [loading, setLoading] = useState(true)
    const { API_URL } = config
    
    const fetchCandidateAddresses = useCallback(
        (address, callback) => {
            if (address) {
                dataAbortController.abort()
                const abort = new AbortController()
                setDataAbortController(abort)
                const requestUrl = `${API_URL}/map/getAddressCandidates?singleLine=${address}`
                authenticatedFetch(requestUrl, { signal: abort.signal })
                    .then(async response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    })
                    .then(response => {
                        const options = response.candidates.map(candidate => ({
                            label: candidate.address,
                            value: candidate.location
                        }))
                        callback(options)
                    })
                    .catch(e => {
                        toast({
                            level: 'error',
                            message:
                                'Address Search: ' +
                                (e.message
                                    ? e.message
                                    : 'Unable to connect to the server. Please try again later.'),
                        })
                        callback([])
                    })
                    .finally(() => setLoading(false))
            }
            else {
                callback([])
            }
        },
        [API_URL]
    )

    return (
        <DataContext.Provider
            value={{
                address,
                setAddress,
                units,
                setUnits,
                unitOptions: addressUnits,
                addressList,
                setAddressList,
                searchRadius,
                setSearchRadius,
                loading,
                centerLatLong,
                setCenterLatLong,
                fetchCandidateAddresses,
                selectedAddress,
                setSelectedAddress
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export { DataContext }
export default withConfig(DataContextProvider)
