import React from 'react'
import {
    FaSlidersH,
    FaThList,
    FaArrowAltCircleUp,
    FaRegArrowAltCircleUp,
} from 'react-icons/fa'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'

import PanelHeader from '../panel/PanelHeader'
import { IconTooltip as Tooltip } from '../Tooltip'

export default ({
    filterExpanded,
    toggleFilter,
    togglePromotedRecords,
    promotedRecords,
    name,
    formName,
    panelTitle,
    extraClass,
    toggleCollapsed,
    isCollapsed,
}) => {
    return (
        <PanelHeader extraClass={extraClass}>
            <div className="panelHeaderText">{`${panelTitle} Explorer`}</div>
            <div className="panelHeaderControls">
                <div className="panelHeaderIconWrapper">
                    {!filterExpanded && (
                        <div
                            className="panelHeaderIconDiv"
                            data-tip={`${
                                promotedRecords
                                    ? `Do not promote Selected Records`
                                    : `Promote  Selected Records`
                            }`}
                            data-for={`${formName}-promoted-records-toggle`}
                            onClick={() =>
                                togglePromotedRecords(!promotedRecords, name)
                            }
                        >
                            {promotedRecords ? (
                                <FaArrowAltCircleUp />
                            ) : (
                                <FaRegArrowAltCircleUp />
                            )}
                            <Tooltip
                                id={`${formName}-promoted-records-toggle`}
                            />
                        </div>
                    )}
                    <div
                        className="panelHeaderIconDiv"
                        data-tip={`${
                            filterExpanded
                                ? `View ${panelTitle} List`
                                : `View ${panelTitle} Filter`
                        }`}
                        data-for={`${formName}-view-toggle`}
                        onClick={() => toggleFilter(!filterExpanded)}
                    >
                        {filterExpanded ? <FaThList /> : <FaSlidersH />}
                        <Tooltip id={`${formName}-view-toggle`} />
                    </div>
                    <div
                        className={`panelHeaderIconDiv`}
                        data-tip={`Collapse / Expand`}
                        data-for={`${formName}-collapse-toggle`}
                        onClick={() =>
                            toggleCollapsed(!isCollapsed, name)
                        }
                    >
                        <Tooltip
                            id={`${formName}-collapse-toggle`}
                        />
                        {isCollapsed ? (
                            <IoIosArrowUp />
                        ) : (
                            <IoIosArrowDown />
                        )}
                    </div>
                </div>
            </div>
        </PanelHeader>
    )
}
