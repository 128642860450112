import React from 'react'

import DetailAnalyteChart from './DetailAnalyteChart'
import DetailWaterLevelChart from './DetailWaterLevelChart'
import ChartTabs from './ChartTabs'
import ChartControls from './ChartControls'
import AnalyteDropdown from './AnalyteDropdown'
import TimeWindowDropdown from './TimeWindowDropdown'
import ResetDropdown from './ResetDropdown'

const DetailChart = () => {
    return (
        <div>
            <ChartTabs />
            <ChartControls />
            <ResetDropdown />
            <AnalyteDropdown />
            <TimeWindowDropdown />
            <DetailAnalyteChart />
            <DetailWaterLevelChart />
        </div>
    )
}

export default DetailChart