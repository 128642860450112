import React, { useContext, useEffect, useMemo, useState } from 'react'

import { TableContext } from './table/TableContext'
import FormsInProgress from './table/FormsInProgress'
import SubmittedForms from './table/SubmittedForms'
import { SimpleSpinner } from '../../elem/Spinner'
import NewUploadButton from './NewUploadButton'
import { DataContext } from './DataContext'
import BulkSubmitButton from './table/BulkSubmitButton'
import { UserContext } from '../../wrappers/UserContext'
import { isPrinter as isPrinterRole } from '../../../utils/user/permissions'

const SubmissionTabs = ({ onChange, active, isPrinter }) => {
    return (
    <>
        <div className="tabs is-large">
            <ul>
                <li
                    className={
                        `${active === 'inProgress' ? 'is-active' : ''} 
                        ${isPrinter ? 'is-hidden' : ''}`
                    }
                    onClick={() => onChange('inProgress')}
                >
                    <span className="link">Forms In Progress</span>
                </li>
                <li
                    className={`${active === 'submitted' ? 'is-active' : ''}`}
                    onClick={() => onChange('submitted')}
                >
                    <span className="link">Submitted Forms</span>
                </li>
            </ul>
            <NewUploadButton isPrinter={isPrinter}/>
        </div>
    </>
)}

export default () => {
    const { loading } = useContext(TableContext)
    const { initializeState, setActiveAgency, setPrimaryKey, setErrorState} = useContext(DataContext)
    const { roles } = useContext(UserContext)    
    const isPrinter = useMemo(() => isPrinterRole(roles), [roles])
    const [activeTab, setActiveTab] = useState('inProgress')
    
    useEffect(() => {
        setActiveAgency(null)
        initializeState({})
        setErrorState({})
        setPrimaryKey(null)
    }, [])
    
    useEffect(() => {
        setActiveTab(!isPrinter ? 'inProgress' : 'submitted')
    }, [isPrinter])

    return (
        <div className="container uploads">
            <SubmissionTabs onChange={setActiveTab} active={activeTab} isPrinter={isPrinter} />
            {activeTab === 'inProgress' && (<BulkSubmitButton />)}
            <div className={`section ${activeTab === 'inProgress' ? 'inProgressSection' : ''}`}>
                {loading ? (
                    <SimpleSpinner />
                ) : activeTab === 'inProgress' ? (
                    <FormsInProgress />
                ) : (
                    <SubmittedForms />
                )}
            </div>
        </div>
    )
}
