import React, { useEffect } from 'react'
// import { useLocation} from 'react-router-dom'

import urls from '../../../utils/constants/urls'
import {SimpleSpinner} from '../../elem/Spinner'

export default () => {
    // const location = useLocation()
    useEffect(() => {
        const t = setTimeout(() => {
            window.location = urls.home
        }, 5000)
        return () => clearTimeout(t)
    }, [])
    // if (location.search.includes('access_denied')) {
    //     window.location = urls.home
    // }
    
    return <SimpleSpinner />
}
