import React from 'react'
import { useTable, useSortBy } from 'react-table'

import TableHeader from '../shared/TableHeader'
import TableBody from '../shared/TableBody'

const Table = ({
    name,
    columns,
    data,
    loading,
    initialSort,
    initialSortDesc,
    containerClass
}) => {
    const initialSortBy = initialSort && initialSortDesc ? [{id: initialSort, desc: initialSortDesc}] : []
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        // Get the state from the instance
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                hiddenColumns: ['FacilityID'],
                sortBy: initialSortBy,
            }, // Pass our hoisted table state
        },
        useSortBy,
    )
    // Render the UI for your table
    return (
        <>
            <div
                className={`container table-container is-small ${containerClass ? containerClass : null}`}
            >
                <table
                    {...getTableProps()}
                    className="table is-striped is-fullwidth is-hoverable explorerTable"
                >
                    <TableHeader headerGroups={headerGroups} />
                    <TableBody
                        {...{
                            getTableBodyProps,
                            page: rows,
                            prepareRow,
                            loading,
                            onClick: () => {}
                        }}
                    />
                </table>
            </div>
        </>
    )
}

export default Table
