import dayjs from 'dayjs'
import { getInputType } from '../form/formFieldParser'
import {
    extractNumericalValue,
    replaceQuotesWithNulls,
    extractDateValue,
} from './numerical'
import { capitalizeText, decapitalizeText } from '../stringUtils'
import { populateConfigWithForeignKeyValues } from './foreignKeys'

const convertStringToBool = v => {
    if (v === 'true') {
        return true
    }
    if (v === 'false') {
        return false
    }
    return v
}

const convertFieldDuplicateString = (IsFieldDuplicate, fkColumnName) => {
    if (IsFieldDuplicate && fkColumnName === 'uploadSampleId') {
        return ' - Duplicate'
    } else {
        return ''
    }
}

const issuesInNavgroup = (groupName, errorState, uploadConfig, level) => {
    const flattenedIssues = Object.keys(errorState).reduce((acc, curr) => {
        const dataAccessor = curr.split('[')[0]
        const errors = errorState[curr]
        if (acc[dataAccessor]) {
            return {
                ...acc,
                [dataAccessor]: [...acc[dataAccessor], ...errors],
            }
        } else {
            return {
                ...acc,
                [dataAccessor]: errors,
            }
        }
    }, {})
    const fieldsAssociatedWithNavGroup = uploadConfig.filter(
        c => c.SectionGroupName === groupName
    )
    const navGroupHasIssues = Object.keys(flattenedIssues)
        .map(dataAccessor => {
            var issues = flattenedIssues[dataAccessor]
            var hasIssues = issues
                .filter(x => x.severity === level)
                .map(issue =>
                    fieldsAssociatedWithNavGroup.find(field => {
                        if (issue.fieldName === '') {
                            return field.DataAccessor === dataAccessor
                        } else {
                            return (
                                field.DataAccessor === dataAccessor &&
                                decapitalizeText(field.ColumnName) ===
                                    issue.fieldName
                            )
                        }
                    })
                )
                .some(x => x)
            return hasIssues
        })
        .some(x => x)
    return navGroupHasIssues
}

const getValueFromDataRow = (fieldName, newRow, config) => {
    if (fieldName.includes('Select') && !fieldName.includes('DateSelect')) {
        const inputName = fieldName.replace('Select', '')
        const associatedConfig = config.find(
            x => decapitalizeText(x.ColumnName) === inputName
        )
        const values = associatedConfig.Values
        const value = newRow[inputName]
        const valueEntry = values.find(x => String(x.code) === String(value))
        if (
            (value === null || value === undefined) &&
            (valueEntry === null || valueEntry === undefined)
        ) {
            return '' // https://coordinatesolutions.atlassian.net/browse/AW-564?focusedCommentId=52088 this comment explains the bug this resolves
        } else
            return {
                active: associatedConfig.active,
                value: value,
                label: valueEntry.codedescription,
            }
    } else {
        if (fieldName.includes('DateSelect')) {
            return newRow[fieldName.replace('DateSelect', '')]
                ? dayjs(newRow[fieldName.replace('DateSelect', '')])
                      .format('MM/DD/YYYY hh:mm A')
                      .toString()
                : ''
        } else if (fieldName.includes('Select')) {
            return newRow[fieldName.includes('Select')]
        } else return newRow[fieldName]
    }
}

const errorsInNavgroup = (groupName, errorState, uploadConfig) =>
    issuesInNavgroup(groupName, errorState, uploadConfig, 'Error')

const warningsInNavgroup = (groupName, errorState, uploadConfig) =>
    issuesInNavgroup(groupName, errorState, uploadConfig, 'Warning')

const removeInputTypeNames = (o, uploadConfig) => {
    return Object.keys(o).reduce((acc, curr) => {
        const formEntry = o[curr]
        const isSelect = curr.includes('Select')
        const isDate = curr.includes('DateSelect')
        const isCheckBox = curr.includes('CheckBox')
        const strippedFieldName = capitalizeText(
            curr
                .replace('Input', '')
                .replace('DateSelect', '')
                .replace('Select', '')
                .replace('CheckBox', '')
        )
        const associatedFieldConfig = uploadConfig.find(
            x => x.ColumnName === strippedFieldName
        )
        if (isCheckBox) {
            return {
                ...acc,
                [strippedFieldName]: formEntry,
            }
        }
        if (isDate) {
            return {
                ...acc,
                [strippedFieldName]: extractDateValue(formEntry),
            }
        }
        if (isSelect && formEntry) {
            if (!associatedFieldConfig) {
                return {
                    ...acc,
                    [strippedFieldName]: extractNumericalValue(formEntry.value),
                }
            }
            return {
                ...acc,
                [strippedFieldName]:
                    getInputType(associatedFieldConfig.DataType) === 'number'
                        ? extractNumericalValue(formEntry.value)
                        : convertStringToBool(
                              replaceQuotesWithNulls(formEntry.value)
                          ),
            }
        }
        return {
            ...acc,
            [strippedFieldName]:
                getInputType(
                    associatedFieldConfig
                        ? associatedFieldConfig.DataType
                        : null
                ) === 'number' && formEntry
                    ? extractNumericalValue(formEntry)
                    : convertStringToBool(replaceQuotesWithNulls(formEntry)),
        }
    }, {})
}

const getValuesFromData = (data, uploadConfig) => {
    const newData = Object.keys(data).reduce((acc, groupName) => {
        const associatedData = data[groupName]
        if (Array.isArray(associatedData)) {
            return {
                ...acc,
                [groupName]: associatedData.map(d =>
                    removeInputTypeNames(d, uploadConfig)
                ),
            }
        } else {
            return {
                ...acc,
                [groupName]: removeInputTypeNames(associatedData, uploadConfig),
            }
        }
    }, {})
    return newData
}

const isChanged = (uploadChanges, dataAccessor, name, idx, submissionState) => {
    if (uploadChanges && uploadChanges[dataAccessor]) {
        if (isNaN(idx)) {
            return !!uploadChanges[dataAccessor].some(
                x => x.column.toLowerCase() === name.toLowerCase()
            )
        } else {
            if (
                submissionState &&
                submissionState[dataAccessor] &&
                submissionState[dataAccessor][idx]
            ) {
                const uploadData = submissionState[dataAccessor][idx]
                const tableIdColumn = dataAccessor + 'Id'
                const uploadChangeIdColumn =
                    dataAccessor.slice(0, 1).toUpperCase() +
                    dataAccessor.slice(1) +
                    'ID'
                const id = uploadData[tableIdColumn]
                if (typeof id !== 'undefined') {
                    const delta = !!uploadChanges[dataAccessor].some(
                        x =>
                            x.column.toLowerCase() === name.toLowerCase() &&
                            x[uploadChangeIdColumn] === id
                    )
                    return delta
                }
            }
        }
    }
    return false
}

const getWarningFromErrorState = (errorState, name, rowIdx, dataAccessor) => {
    if (errorState) {
        if (isNaN(rowIdx) && errorState[dataAccessor]) {
            const e = errorState[dataAccessor].find(
                x => x.fieldName === name && x.severity === 'Warning'
            )
            return e
        } else {
            if (errorState[`${dataAccessor}[${rowIdx}]`]) {
                if (errorState[`${dataAccessor}[${rowIdx}]`]) {
                    const e = errorState[`${dataAccessor}[${rowIdx}]`].find(
                        x => x.severity === 'Warning' && x.fieldName === name
                    )
                    return e ? e : null
                }
            }
            return null
        }
    }
}

const getApiErrorFromErrorState = (
    errorState,
    name,
    rowIdx,
    dataAccessor,
    formError
) => {
    if (errorState && !formError) {
        if (isNaN(rowIdx) && errorState[dataAccessor]) {
            const e = errorState[dataAccessor].find(
                x => x.fieldName === name && x.severity === 'Error'
            )
            return e
        } else {
            if (errorState[`${dataAccessor}[${rowIdx}]`]) {
                if (errorState[`${dataAccessor}[${rowIdx}]`]) {
                    const e = errorState[`${dataAccessor}[${rowIdx}]`].find(
                        x => x.severity === 'Error' && x.fieldName === name
                    )
                    return e ? e : null
                }
            }
            return null
        }
    }
}

const getConfigRefCodeValue = (field, value, uploadConfig) => {
    const associatedConfig = uploadConfig.find(x => x.ColumnName === field)

    if (associatedConfig && associatedConfig.Values) {
        const associatedRefCode = associatedConfig.Values.find(
            x => x.code === value
        )
        if (associatedRefCode) {
            return associatedRefCode.codedescription
        }
    }
    return value
}

const getInputNameFromConfig = (config, fieldName) => {
    if (!config) {
        return fieldName
    }
    const name = decapitalizeText(config.ColumnName)
    switch (config.ControlType) {
        case 'TextBox':
            return name
        case 'Select':
            return `${name}Select`
        case 'MultiSelect':
            return `${name}Select`
        case 'CheckBox':
            return `${name}CheckBox`
        case 'DateDayPicker':
            return `${name}DateSelect`
        case 'DatePicker':
            return `${name}DateSelect`
        case 'Creatable':
            return `${name}Select`
        default:
            return name
    }
}

const isNotNullOrUndefined = value => {
    return !(typeof value === 'undefined' || value === null)
}

const getSelectValue = (values, initialValue, defaultValue) => {
    const value = isNotNullOrUndefined(initialValue)
        ? initialValue
        : defaultValue
        ? defaultValue
        : ''
    const option = values.find(
        x =>
            x.code === value.toString() ||
            x.code === value ||
            x.code === parseInt(value) ||
            (value === true && x.code === 'true') ||
            (value === false && x.code === 'false')
    )
    if (option) {
        return {
            label: option.codedescription,
            value: option.code,
            active: option.active,
        }
    } else {
        return value
    }
}

const getCreatableSelectValue = (values, initialValue, defaultValue) => {
    const value = isNotNullOrUndefined(initialValue)
        ? initialValue
        : defaultValue
        ? defaultValue
        : ''
    const option = values.find(
        x =>
            x.code === value.toString() ||
            x.code === value ||
            x.code === parseInt(value)
    )
    if (option) {
        return {
            label: option.codedescription,
            value: option.code,
            active: option.active,
        }
    } else {
        if (value !== '') {
            return { label: value, value: value, active: true }
        } else {
            return value
        }
    }
}

const getCheckBoxValue = (initialValue, defaultValue) => {
    const value = isNotNullOrUndefined(initialValue)
        ? initialValue
        : defaultValue
        ? defaultValue
        : 0
    return value
}

const getValueFromUploadConfig = (initialValue, config) => {
    const value = initialValue
    switch (config.ControlType) {
        case 'TextBox':
            return value
        case 'Select':
            return getSelectValue(config.Values, value, config.DefaultValue)
        case 'MultiSelect':
            return getSelectValue(config.Values, value, config.DefaultValue)
        case 'DateDayPicker':
            return value
                ? dayjs(value)
                      .format('MM/DD/YYYY')
                      .toString()
                : null
        case 'DatePicker':
            return value
                ? dayjs(value)
                      .format('MM/DD/YYYY hh:mm A')
                      .toString()
                : null
        case 'CheckBox':
            return getCheckBoxValue(value, config.DefaultValue)
        case 'Creatable':
            return getCreatableSelectValue(
                config.Values,
                value,
                config.DefaultValue
            )
        default:
            return value
    }
}

const transformDBValuesToFormValues = (x, sectionConfig) => {
    if (!x) {
        return null
    }
    let formValues = {}
    Object.keys(x).reduce((acc, fieldName) => {
        let config = sectionConfig.find(
            c => decapitalizeText(c.ColumnName) === fieldName
        )
        let name = getInputNameFromConfig(config, fieldName)
        const dbValue = x[fieldName]
        const value = config
            ? getValueFromUploadConfig(dbValue, config)
            : dbValue
        formValues[name] = value
        return acc
    }, {})
    return formValues
}

const getDefaultValuesFromSubmissionState = (
    panelName,
    initialUploadConfig,
    submissionState,
    printable = false
) => {
    // populate uploadConfig with foreign key values from other tables
    const uploadConfig = populateConfigWithForeignKeyValues(
        initialUploadConfig,
        submissionState
    )

    // populate table with config
    if (panelName || printable) {
        let relatedConfig = uploadConfig.filter(
            x => x.SectionGroupName === panelName
        )
        if (printable) {
            relatedConfig = uploadConfig
        }
        const subSections = [
            ...new Set(relatedConfig.map(x => x.UploadSectionGroupName)),
        ]
        let defaultValues = {}
        subSections.reduce((acc, section) => {
            const sectionData = relatedConfig.filter(
                x => x.UploadSectionGroupName === section
            )
            // console.log('sectionData being parsed:', sectionData)
            sectionData.reduce((accc, row) => {
                if (Array.isArray(submissionState[row.DataAccessor])) {
                    const rows = submissionState[row.DataAccessor]
                    let values = new Array(rows.length)
                    for (let i = 0; i < rows.length; i++) {
                        values[i] = transformDBValuesToFormValues(
                            rows[i],
                            relatedConfig
                        )
                    }
                    // const values = rows.map(x => {
                    //     return transformDBValuesToFormValues(
                    //         x,
                    //         relatedConfig,
                    //     )
                    // })
                    defaultValues[row.DataAccessor] = values
                    return accc
                } else {
                    // return {
                    //     [row.DataAccessor]: transformDBValuesToFormValues(
                    //             submissionState[row.DataAccessor],
                    //             relatedConfig
                    //         ),
                    //     ...accc,
                    // }
                    defaultValues[
                        row.DataAccessor
                    ] = transformDBValuesToFormValues(
                        submissionState[row.DataAccessor],
                        relatedConfig
                        )
                    return accc
                }
            }, {})
            return acc
        }, {})

        // console.log('defaultValues:', defaultValues)
        return defaultValues
    } else {
        return {}
    }
}

export {
    getConfigRefCodeValue,
    removeInputTypeNames,
    getValuesFromData,
    isChanged,
    getApiErrorFromErrorState,
    getWarningFromErrorState,
    warningsInNavgroup,
    errorsInNavgroup,
    convertFieldDuplicateString,
    convertStringToBool,
    getValueFromDataRow,
    getDefaultValuesFromSubmissionState,
}
