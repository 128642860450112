import toast from '../../components/elem/Toast'

const getChartConfig = (config, configField) => {
    try {
        const relatedData = config['CHART'][configField]
        const title = config['FACILITY_TITLE']
        return {
            dateField: relatedData['DATE_FIELD'],
            valueField: relatedData['VALUE_FIELD'],
            keyField: relatedData['KEY_FIELD'],
            facilityName: title,
        }
    } catch {
        toast({
            level: 'error',
            message: `Unable to configure chart data for ${configField}`,
            alert: true,
        })
        return {}
    }
}

export default getChartConfig