import React, { createContext, useCallback } from 'react'
import { useAuth } from 'oidc-react'

const defaultOptions = {
    cache: 'default',
    headers: {
        CacheControl: 'max-age=3600'
    }
}

const APIRequestContext = createContext(null)

const APIRequestContextProvider = ({ children }) => {
    const { userManager } = useAuth()

    const authenticatedFetch = useCallback(async (endpoint, options) => {
        const u = await userManager.getUser()
        let fetchOptions = {}
        const authorizationHeader = u ? {
            Authorization: `Bearer ${u.access_token}`,
        } : {}
        if (options) {
            fetchOptions = {
                ...defaultOptions,
                ...options,
                headers: {
                    ...defaultOptions.headers,
                    ...options.headers,
                    ...authorizationHeader
                },
            }
        } else {
            fetchOptions = {
                method: 'GET',
                ...defaultOptions,
                headers: {
                    ...defaultOptions.headers,
                    ...authorizationHeader
                },
            }
        }
        // see https://codesandbox.io/s/ek144?file=/src/services/ApiService.ts:386-685 for the inspiration
        return fetch(endpoint, fetchOptions).then(async res => {
            if(res.status === 401) { //if an unauthorised call gets triggered then renew the user and set a new token 
                const renewedUser = await userManager.signinSilent()
                fetchOptions.headers.Authorization = `Bearer ${renewedUser.access_token}`
                return await fetch(endpoint, fetchOptions)
            }
            return res 
        })
    }, [userManager])

    return (
        <APIRequestContext.Provider
            value={{authenticatedFetch}}
        >
            {children}
        </APIRequestContext.Provider>
    )
}

export { APIRequestContext }
export default APIRequestContextProvider
