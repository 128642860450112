import React, { useContext, useCallback, useState } from 'react'

import { DataContext } from './DataContext'
import { DataContext as MapDataContext } from '../DataContext'
import { MapControlContext } from '../MapControlContext'
import SearchBar from './SearchBar'
import UnitsField from './UnitsField'

const SearchForm = () => {
    const { selectWellsWithinRadius } = useContext(MapDataContext)
    const { toggleDisplaySearchByAddress } = useContext(MapControlContext)
    const { searchRadius, units, selectedAddress } = useContext(DataContext)
    const [searchError, setSearchError] = useState(null)

    const onSubmit = useCallback(
        e => {
            e.preventDefault()
            // clear existing search error
            setSearchError(null)
            const location = selectedAddress.value
            // on submit, create a buffer around the search location and select wells within
            selectWellsWithinRadius(location, searchRadius, units.value)
                .then(() => 
                    // on successful search, hide the searchByAddress popup
                    toggleDisplaySearchByAddress(false)
                )
                .catch(err => 
                    // if error, set search error message
                    setSearchError(err)
                )
            return null
        },
        [searchRadius, units, selectedAddress]
    )

    return (
        <form className="form addressSearchForm" onSubmit={e => onSubmit(e)}>
            <SearchBar />
            <UnitsField />
            {searchError && (
                <div className="field">
                    <p class="help is-danger has-text-centered">
                        {searchError}
                    </p>
                </div>
            )}
            <div className="field is-grouped is-grouped-centered">
                <div className="control">
                    <button
                        type="submit"
                        className="button is-success is-small"
                    >
                        Go
                    </button>
                </div>
                <div className="control">
                    <button
                        type="button"
                        className="button is-small"
                        onClick={() => {
                            toggleDisplaySearchByAddress(false)
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </form>
    )
}

export default SearchForm
