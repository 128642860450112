import React, { useContext, useMemo } from 'react'

import Modal from '../../../elem/Modal'
import { DataContext } from '../DataContext'

export default ({ deleteFunction, accessor }) => {
    const { displayDeleteModal, setDisplayDeleteModal } = useContext(DataContext)
    const sectionAccessor = useMemo(
        () =>
            displayDeleteModal && displayDeleteModal.sectionConfig 
            && Array.isArray(displayDeleteModal.sectionConfig) && displayDeleteModal.sectionConfig[0].DataAccessor
    , [displayDeleteModal])

    return (
        <Modal
            display={ sectionAccessor === accessor ? displayDeleteModal.display : false }
            confirmAction={() => {
                    deleteFunction(displayDeleteModal.tableProps)
                    setDisplayDeleteModal({ ...displayDeleteModal, display: false })
                }
            } 
            confirmText={`Delete`}
            denyAction={() => setDisplayDeleteModal({ ...displayDeleteModal, display: false })}
            denyText={`Cancel`}
            title={'Delete Confirmation'}
        >
            Are you sure you want to delete this record?
        </Modal>
    )
}
