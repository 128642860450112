import React, { useContext } from 'react'
import ReactSelect from 'react-select'
import customStyles from '../../../../elem/form/SelectStyles'

import { DataContext } from './DataContext'

export default () => {
    const { unitOptions, units, setUnits } = useContext(DataContext)
    return (
        <ReactSelect
            className="select is-small is-multiple is-fullwidth reactSelect"
            classNamePrefix="unitsDropdown"
            options={unitOptions}
            styles={customStyles}
            value={units}
            name="units"
            onChange={value => setUnits(value)}
            menuPlacement="auto"
            width="30%"
        />
    )
}