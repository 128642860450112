const getFieldNameFromParam = (field, filterFields) => {
    // if there is a filterfield with the same name as field,
    // and it has a column title field, use that column title
    const matchingFilter = filterFields.find(x => x.ControlName === field)
    if (matchingFilter && matchingFilter.ColumnTitle) {
        return matchingFilter.ColumnTitle
    }

    // else parse the field manually
        return field
            .split(/(?=[A-Z])/) // split on uppercase letters
            .join(' ') // join with spaces
            .replace('I D', 'ID') // replace I D with ID
            .replace('_', ' ') // replace _ with " "
            .replace(/(?:^|\s)\S/g, a => a.toUpperCase()) // Capitalize first letter of each word
}

const getFieldNameFromColumn = (field, filterFields) => {
    // if there is a filterfield with the same name as field,
    // and it has a column title field, use that column title
    const matchingFilter = filterFields.find(x => x.ColumnName === field)
    if (matchingFilter && matchingFilter.ColumnTitle) {
        return matchingFilter.ColumnTitle
    }

    // else parse the field manually
        return field
            .split(/(?=[A-Z])/) // split on uppercase letters
            .join(' ') // join with spaces
            .replace('I D', 'ID') // replace I D with ID
            .replace('_', ' ') // replace _ with " "
            .replace(/(?:^|\s)\S/g, a => a.toUpperCase()) // Capitalize first letter of each word
}

const decapitalizeText = s => s && s.length > 0 ? s[0].toLowerCase() + s.substring(1) : null
const capitalizeText = s => s && s.length > 0 ? s[0].toUpperCase() + s.substring(1) : null

export { getFieldNameFromParam, getFieldNameFromColumn, decapitalizeText, capitalizeText }