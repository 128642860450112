import concave from '@turf/concave'
import { featureCollection, point } from '@turf/helpers'
import circle from '@turf/circle'
import { transform } from 'ol/proj'
import { stringify } from 'wellknown'
import GeoJSON from 'ol/format/GeoJSON'

const getTurfFeatureCollection = (
    points,
    from = 'EPSG:3857',
    to = 'EPSG:4326'
) => {
    return featureCollection(
        points.map(x =>
            point(transform(x.getGeometry().getCoordinates(), from, to))
        )
    )
}

const getConcavePolygon = olFeatures => {
    const collection = getTurfFeatureCollection(olFeatures)
    return concave(collection)
}

const convertPolyToWKT = turfPolygons => {
    if (turfPolygons) {
        const wkt = stringify(turfPolygons)
        return wkt
    } else {
        return null
    }
}

const getCircleAboutLocation = (location, radius, units) => {
    const { x, y } = location
    const centerPt = point([x, y])
    const options = { steps: 20, units: units }
    const circlePoly = circle(centerPt, radius, options)
    var circleFeature = new GeoJSON().readFeatures(circlePoly)

    if (circleFeature && circleFeature.length) {
        const c = circleFeature[0]
        c.getGeometry().transform('EPSG:4326', 'EPSG:3857')
        return c
    }

    return null
}

export { getConcavePolygon, convertPolyToWKT, getCircleAboutLocation }
