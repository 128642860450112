import React from 'react'

export default ({ display, confirmAction, confirmText, denyAction, denyText, title, children }) => (
    <div className={`modal ${display ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">{title}</p>
            </header>
            <section className="modal-card-body">{children}</section>
            <footer className="modal-card-foot">
                <button className="button is-link" type='button' onClick={confirmAction}>
                    {confirmText}
                </button>
                <button className="button" type='button' onClick={denyAction}>
                    {denyText}
                </button>
            </footer>
        </div>
    </div>
)
