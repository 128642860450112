import deepEqual from "deep-equal"

const setAddressValues = (values, setValue) => {
    const dataAccessor = 'address'
    if (values && values[dataAccessor]) {
        const addressValues = values[dataAccessor]
        // if the copy to address checkbox is present and checked, copy data over
        // from the corresponding physical address fields to mailing address
        const copyPhysicalAddressName = Object.keys(addressValues).find(x => x.includes('Copy'))
        if (copyPhysicalAddressName && addressValues[copyPhysicalAddressName]) {
            const valueKeys = ['Address1', 'Address2', 'City', 'PostalCode', 'Country', 'State']
            const valueKeyFields = Object.keys(addressValues).filter(x => valueKeys.find(y => x.includes(y)))
            const keyFields = valueKeys.map(x => ({
                key: x,
                physical: valueKeyFields.find(y => y.includes(x) && !y.includes('Mailing')),
                mailing: valueKeyFields.find(y => y.includes(x) && y.includes('Mailing'))
            }))

            // map the address values from physical address to mailing address
            keyFields.forEach(x => {
                const fieldName = `address.${x.mailing}`
                if (x.key !== 'Country') {
                    const targetValue = addressValues[x.mailing]
                    const sourceValue = addressValues[x.physical]
                        if (!deepEqual(sourceValue, targetValue)) {
                            setValue(fieldName, addressValues[x.physical])
                        }
                } else {
                    const targetValue = addressValues[x.mailing]
                    const sourceValue = addressValues[x.physical] ? addressValues[x.physical].value : null
                    if (!deepEqual(sourceValue, targetValue)) {
                        setValue(fieldName, sourceValue)
                    }
                }
                return null
            })
        }
    }
}

const setReactiveValues = (values, setValue) => {
    // handler for address values
    setAddressValues(values, setValue)
}

export { setReactiveValues }