import React, { useContext } from 'react'

import AgencyConfirmation from '../AgencyConfirmation'
import EDDDialogue from './EDDDialogue'
import { DataContext } from '../DataContext'

export default () => {
    const { activeAgency, setActiveAgency } = useContext(DataContext)

    return activeAgency ? <EDDDialogue /> : <AgencyConfirmation onSubmit={setActiveAgency}/>
}
