import React, { createContext } from 'react'

import { waterLevelPanel as panelClassConfig } from '../../../../utils/styles/panelStyles'
import Explorer from '../../../elem/list/Explorer'

const DataContext = createContext(null)

export default ({ width }) => {
    return (
        <Explorer 
            formName='waterLevel' 
            name='WaterLevel' 
            apiController='waterLevel' 
            DataContext={DataContext} 
            width={width} 
            panelTitle={'Water Level'}
            panelClassConfig={panelClassConfig}
        />
    )
}