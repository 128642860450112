import React, { useContext, useMemo } from 'react'
import get from 'lodash.get'

import { FaAsterisk } from 'react-icons/fa'
import { DataContext } from '../DataContext'
import { ExistingDataContext } from './ExistingSubmissionDataContext'
import {
    getApiErrorFromErrorState,
    getWarningFromErrorState,
    isChanged,
} from '../../../../utils/submissions/values'
import FieldErrors from './FieldErrors'

const TextArea = ({
    fieldName,
    name,
    helper,
    register,
    registerParams,
    errors,
    dataAccessor,
    required,
    displayLabel,
    rowIdx,
    disabled,
    watch,
    extraClass,
    printable = false,
}) => {
    const { submissionState, activePanel, viewOnly, errorState } = useContext(
        DataContext
    )
    const c = useContext(ExistingDataContext)
    const uploadChanges = c ? c.uploadChanges : null
    // const apiError = errorState[activePanel] ? errorState[activePanel].find(x => x.fieldName === name) :  null
    const filterName = `${dataAccessor}${
        typeof rowIdx !== 'undefined' ? `[${rowIdx}]` : ''
    }.${name}`

    const changed = useMemo(
        () =>
            isChanged(
                uploadChanges,
                dataAccessor,
                name,
                rowIdx,
                submissionState
            ),
        [uploadChanges, dataAccessor, name, rowIdx, submissionState]
    )

    // construct input props
    const inputProps = {
        name: filterName,
        ref: register(registerParams),
    }

    const formError =
        errors && get(errors, inputProps.name)
            ? get(errors, inputProps.name)
            : null

    const warning = useMemo(() => {
        return getWarningFromErrorState(errorState, name, rowIdx, dataAccessor)
    }, [errorState, name, activePanel, rowIdx, formError, dataAccessor])

    const apiError = useMemo(() => {
        return getApiErrorFromErrorState(
            errorState,
            name,
            rowIdx,
            dataAccessor,
            formError
        )
    }, [errorState, name, activePanel, rowIdx, formError, dataAccessor])

    return (
        <div className={`column is-full no-vertical-padding formInputWrapper`}>
            <div className="formInputLayout">
                <div className="field">
                    {displayLabel && (
                        <div className="field-label is-small">
                            <label className="label">
                                <span>{fieldName}</span>
                                {required && !printable ? (
                                    <span className="requiredStar has-text-danger">
                                        {<FaAsterisk />}
                                    </span>
                                ) : null}{' '}
                            </label>
                        </div>
                    )}
                    <div className="field-body">
                        <div className="field is-expanded">
                            <div className="field has-addons">
                                {!printable && (
                                    <div
                                        className={`control is-expanded ${
                                            helper ? 'has-icons-left' : ''
                                        }`}
                                        data-for={inputProps.name}
                                        data-tip={watch(inputProps.name)}
                                    >
                                        {!(viewOnly || disabled) ? (
                                            <textarea
                                                {...inputProps}
                                                name={inputProps.name}
                                                ref={register(registerParams)}
                                                rows={5}
                                                className={`textarea is-small formInput 
                                                        ${
                                                            changed
                                                                ? 'is-changed'
                                                                : ''
                                                        }
                                                        ${extraClass}
                                                        `}
                                            />
                                        ) : (
                                            <textarea
                                                disabled
                                                {...inputProps}
                                                rows={5}
                                                className={`textarea is-small ${
                                                    changed ? 'is-changed' : ''
                                                }`}
                                            />
                                        )}
                                        <FieldErrors
                                            formError={formError}
                                            warning={warning}
                                            apiError={apiError}
                                        />
                                    </div>
                                )}
                                {printable && (
                                    <div className={`control is-expanded`}>
                                        <textarea
                                            disabled
                                            {...inputProps}
                                            // name={inputProps.name}
                                            className={`textarea is-small is-printable`}
                                            // value={initialValue}
                                        />
                                    </div>
                                )}
                            </div>
                            {helper && !printable && <p className="help">{helper}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TextArea
