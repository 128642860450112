import React, { useContext } from 'react'
import { Controller } from 'react-hook-form'
import { FaAsterisk, FaInfoCircle } from 'react-icons/fa'

import { DataContext } from '../DataContext'
import { Tooltip } from '../../../elem/Tooltip'

const CheckBox = ({
    name,
    fieldName,
    control,
    helper,
    formWidth,
    dataAccessor,
    rowIdx,
    required,
    disabled,
    watch
}) => {
    const {
        viewOnly,
    } = useContext(DataContext)

    const inputName = `${dataAccessor}${
        typeof rowIdx !== 'undefined' ? `[${rowIdx}]` : ''
    }.${name}CheckBox`

    return (
        <div
            className={`column ${
                formWidth === 'full' ? 'is-one-third' : 'is-full'
            } no-vertical-padding formInputWrapper`}
        >
            <div className="formInputLayout">
                <div className="field">
                    <div className="field-label is-small">
                        <label className="label checkboxLabel">
                            <Controller
                                as={
                                    <input
                                        type="checkbox"
                                        className="formCheckbox"
                                        disabled={viewOnly || disabled}
                                    />
                                }
                                defaultChecked={watch(`${inputName}`) ? true : false}
                                onChange={([e]) => {
                                    return e.currentTarget.checked
                                }}
                                name={inputName}
                                control={control}
                                valueName="checked"
                                isDisabled={viewOnly || disabled }
                            />
                            <span>{fieldName}</span>
                            {required ? (
                                <span className="requiredStar has-text-danger">
                                    {<FaAsterisk />}
                                </span>
                            ) : null}
                            {helper && (
                                <div
                                    data-tip={`${helper}`}
                                    data-for={inputName}
                                    className="formHelper inline"
                                >
                                    <span className="is-static is-small">
                                        <FaInfoCircle />
                                        <Tooltip
                                            id={inputName}
                                            className={`tooltip-2`}
                                        />
                                    </span>
                                </div>
                            )}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckBox