import React, { useEffect, useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import deepEqual from 'deep-equal'
import formFieldParser, { formValidate, formInputSelector } from '../../../utils/form/formFieldParser'
import { PanelStyleContext } from '../panel/PanelStyleContext'

const Form = ({
    formFields,
    onSubmit,
    onChange,
    onReset,
    formWidth,
    formName
}) => {
    const {
        register,
        control,
        handleSubmit,
        setError,
        clearError,
        errors,
        getValues,
        setValue,
        triggerValidation,
        unregister,
        watch,
        reset,
    } = useForm({
        mode: 'onChange'
    })

    const { backgroundColorClass } = useContext(PanelStyleContext)
    const fields = formFieldParser(
        formFields,
        register,
        unregister,
        control,
        getValues,
        setValue,
        setError,
        clearError,
        errors,
        formWidth,
        formName,
        backgroundColorClass,
        watch,
        formValidate,
        formInputSelector
    )

    const w = watch({nest: true})
    const [formValues, setFormValues] = useState({})
    // const buttonSize = formWidth === 'full' ? 'is-normal' : 'is-small'
    const buttonSize = 'is-small'

    const formHasErrors = errors => {
        const hasErrors = Object.keys(errors).map(errorKey => {
            const fieldErrors = errors[errorKey]
            if (typeof fieldErrors == 'object' && fieldErrors.length) {
                if (fieldErrors.find(x => typeof x !== 'undefined')) {
                    return true
                } else {
                    return false
                }
            }
            return true
        }).find(x => x === true)
        return hasErrors
    }

    useEffect(() => {
        // initialize form values
        if (Object.keys(w).length !== Object.keys(formValues).length) {
            setFormValues(w)
        }

        // if the form state has updated, get the new results count
        if (Object.keys(w).length && !deepEqual(formValues, w)) {
            if (!Object.keys(errors).length) {
                if (!formHasErrors(errors)) {
                    onChange(null, w)
                    setFormValues(w)
                }
            }
        }
    }, [w])

    return (
        <div className="container formWrapper">
            <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                className="columns is-multiline explorerForm"
            >
                {fields}
                <div className="column is-full">
                    <div className="field is-horizontal is-grouped is-grouped-centered formSubmitWrapper">
                        <div className="control is-half">
                            <button
                                className={`button is-primary ${backgroundColorClass} ${buttonSize} formSubmitButton`}
                                type="submit"
                                onClick={e => {
                                    e.preventDefault()
                                    triggerValidation().then(() => {
                                        if (!formHasErrors(errors)) {
                                            handleSubmit(onSubmit)()
                                        }
                                    })
                                }}
                            >
                                Apply Filter
                            </button>
                        </div>
                        <div className="control is-half">
                            <button
                                className={`button is-secondary ${buttonSize} formSubmitButton`}
                                type="reset"
                                onClick={() => { 
                                    onReset(getValues, setValue)
                                    reset()
                                    handleSubmit(onSubmit)
                                }}
                            >
                                Clear All Filters
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Form
