import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
    useCallback,
} from 'react'
import {
    ComposedChart,
    Line,
    YAxis,
    XAxis,
    Tooltip,
    Label,
    CartesianGrid,
    ResponsiveContainer,
    ReferenceArea,
    Legend,
} from 'recharts'

import { DataContext } from './DataContext'
import Message from '../../../../elem/chart/Message'
import Spinner from '../../../../elem/Spinner'
import ChartTooltip from '../../../../elem/chart/Tooltip'
import { dateToString } from '../../../../../utils/dateUtils'
import { AppStateContext } from '../../AppStateContext'
import withConfig from '../../../../wrappers/withConfig'
import dayjs from 'dayjs'
import palette from '../../../../../utils/chart/palette'
import { getAxisYDomain } from '../../../../../utils/chart/slice'
import getChartConfig from '../../../../../utils/chart/getChartConfig'
import { roundToFourDigits } from '../../../../../utils/chart/values'

const CustomLegend = ({ legendPayload }) => {
    return (
        <ul className="recharts-default-legend" style={{ textAlign: 'center' }}>
            {legendPayload.map((item, index) => (
                <li
                    key={`line-legend-${index}`}
                    className={`recharts-legend-item legend-item-${index}`}
                    style={{ display: 'inline-block', marginRight: '10px' }}
                >
                    <svg
                        className="recharts-surface"
                        width="14"
                        height="14"
                        viewBox="0 0 32 32"
                        version="1.1"
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginRight: '4px',
                        }}
                    >
                        <path
                            stroke="none"
                            fill={item.color}
                            d="M0,4h32v24h-32z"
                            className="recharts-legend-icon"
                        ></path>
                    </svg>
                    <span className="recharts-legend-item-text">
                        {item.value}
                    </span>
                </li>
            ))}
        </ul>
    )
}

export default withConfig(({ config }) => {
    const {
        waterLevelChartData,
        loading,
        tooManyFeatures,
        zoomTrigger,
        isCollapsed
    } = useContext(DataContext)
    const {
        mapState: { selectedFeatures },
    } = useContext(AppStateContext)

    const { dateField, valueField, keyField, facilityName } = getChartConfig(config, 'WATER_LEVEL')

    const [data, setData] = useState([])

    const [left, setLeft] = useState('dataMin')
    const [right, setRight] = useState('dataMax')
    const [refAreaLeft, setRefAreaLeft] = useState('')
    const [refAreaRight, setRefAreaRight] = useState('')
    const [top, setTop] = useState('dataMax')
    const [bottom, setBottom] = useState('dataMin')

    const zoom = useCallback(() => {
        let RAL = refAreaLeft
        let RAR = refAreaRight
        if (RAL === RAR || RAR === '') {
            RAL = ''
            RAL = ''
            return
        }

        // xAxis domain
        if (RAL > RAR) {
            const temp = RAL
            RAL = RAR
            RAR = temp
        }

        // yAxis domain
        const leftAxisDomain = getAxisYDomain(RAL, RAR, (x) => !x.includes(dateField), 0, data, dateField)
        setBottom(leftAxisDomain[0])
        setTop(leftAxisDomain[1])

        setLeft(RAL)
        setRight(RAR)
        setRefAreaRight('')
        setRefAreaLeft('')
    }, [refAreaLeft, refAreaRight, data])

    const zoomOut = useCallback(() => {
        setRefAreaLeft('')
        setRefAreaRight('')
        setLeft('dataMin')
        setRight('dataMax')
        setTop('dataMax')
        setBottom('dataMin')
    }, [data])

    useEffect(() => {
        zoomOut()
    }, [zoomTrigger])

    const uniqueSeries = useMemo(
        () =>
            [
                ...new Set(
                    waterLevelChartData.map(x => x[keyField])
                ),
            ]
                .sort()
                .map((x, idx) => ({
                    name: x,
                    color: palette[idx % palette.length],
                })),
        [waterLevelChartData]
    )

    const seriesData = useMemo(() => { 
        if (waterLevelChartData && waterLevelChartData.length) {
            const dates = [
                            ...new Set(
                                waterLevelChartData.map(x => x[dateField])
                            ),
                        ]
            const d = dates.reduce((x, c) => {
                const entry = uniqueSeries.reduce((acc, curr) => {
                    const matchDate = waterLevelChartData.find(w => w[dateField] === c && w[keyField] === curr.name)
                    if (matchDate) {
                        return {
                            ...acc,
                            [curr.name]: matchDate[valueField]
                        }
                    } else {
                        return acc
                    }
                }, {[dateField]: c})

                return [
                    ...x,
                    entry
                ]
            }, [])
            return d
        }
        return waterLevelChartData
    }, [waterLevelChartData, uniqueSeries])

    useEffect(() => {
        if (seriesData && seriesData.length) {
            const d = seriesData.map(x => ({
                ...x,
                [dateField]: dayjs(x[dateField])
                    .toDate()
                    .getTime(),
            }))
            setData(d)
        }
    }, [seriesData])

    const legendData = useMemo(
        () =>
            uniqueSeries &&
            uniqueSeries.map(({ name, color }) => ({
                color,
                type: 'line',
                dataKey: name,
                value: name,
            })),
        [uniqueSeries]
    )

    if (loading || typeof(facilityName) === 'undefined') {
        return <Spinner extraClass="chartWrapper" />
    }

    if (tooManyFeatures) {
        return (
            <Message
                text={`Too Many ${facilityName}s Selected`} isCollapsed={isCollapsed}
                subText={`Select less than 10 ${facilityName.toLowerCase()}s to chart water levels`}
            />
        )
    }

    if (!selectedFeatures.length) {
        return (
            <Message
                text={`No ${facilityName}s Selected`} isCollapsed={isCollapsed}
                subText={`Select up to 10 ${facilityName.toLowerCase()}s to chart water levels`}
            />
        )
    }

    if (
        selectedFeatures.length &&
        !(waterLevelChartData && waterLevelChartData.length)
    ) {
        return (
            <Message
                text={'No Water Level Data Available'} isCollapsed={isCollapsed}
                subText={`Select ${facilityName.toLowerCase()}s with water level measurements in specified time period to generate this chart`}
            />
        )
    }

    // construct chart
    const yAxisLabel = `Depth to Water (ft bls)`
    const xAxisLabel = 'Measurement Date'

    // shared chart props
    const animationDuration = 200

    // create lines for each facility
    // AW-595
    const lines = uniqueSeries.map(({ name, color }, idx) => (
        <Line
            key={`time-series-${name}`}
            type="monotone"
            dataKey={name}
            name={name}
            unit={`ft bls`}
            stroke={color}
            connectNulls 
            strokeWidth={1}
            dot={{ r: 1, fill: color, stroke: color }}
            activeDot={{ r: 5, fill: color, stroke: color }}
            animationDuration={animationDuration}
        />
    ))

    return (
        <div className="chart">
            <div className={ isCollapsed ? `is-collapsed` : `aggregateChartWrapper`}>
                <ResponsiveContainer width="100%" minHeight={'250px'}>
                    <ComposedChart
                        data={data.slice()}
                        margin={{
                            top: 10,
                            right: 20,
                            left: 25,
                            bottom: 25,
                        }}
                        onMouseDown={e => e && e.activeLabel && setRefAreaLeft(e.activeLabel)}
                        onMouseMove={e => e && e.activeLabel && refAreaLeft && setRefAreaRight(e.activeLabel)}
                        // eslint-disable-next-line react/jsx-no-bind
                        onMouseUp={() => zoom()}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            allowDataOverflow
                            type="number"
                            dataKey={dateField}
                            domain={[left, right]}
                            tickFormatter={unixTime => dateToString(unixTime)}
                        >
                            <Label
                                value={xAxisLabel}
                                offset={-10}
                                position="insideBottom"
                                className="nitrateChartXAxisLabel"
                            />
                        </XAxis>
                        <YAxis 
                            reversed={true}
                            interval='preserveStartEnd'
                            // allowDataOverflow 
                            type="number" 
                            domain={[bottom, top]} 
                            tickFormatter={v => roundToFourDigits(v)} 
                            yAxisId={0}
                        >
                            <Label
                                value={yAxisLabel}
                                angle={-90}
                                offset={20}
                                position="insideLeft"
                                className="nitrateChartYAxisLabel"
                            />
                        </YAxis>
                        <Tooltip content={<ChartTooltip stagger={90}/>} allowEscapeViewBox={{x: true, y: true}}/>
                        <Legend
                            content={
                                <CustomLegend legendPayload={legendData} />
                            }
                            verticalAlign="bottom"
                            wrapperStyle={{
                                bottom: `0`,
                                left: 0,
                                alignItems: 'center',
                                width: '100%',
                            }}
                        />
                        {lines}
                        {/* {dots} */}
                        {refAreaLeft && refAreaRight ? (
                            <ReferenceArea
                                yAxisId={0}
                                x1={refAreaLeft}
                                x2={refAreaRight}
                                strokeOpacity={0.3}
                            />
                        ) : null}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
})
