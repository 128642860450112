import React, {
    useContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react'
import dayjs from 'dayjs'
import {
    ComposedChart,
    Line,
    YAxis,
    XAxis,
    Tooltip,
    Label,
    CartesianGrid,
    ResponsiveContainer,
    ReferenceArea,
} from 'recharts'

import { DataContext } from '../DataContext'
import Message from '../../../../elem/chart/Message'
import ChartTooltip from '../../../../elem/chart/Tooltip'
import { dateToString } from '../../../../../utils/dateUtils'
import palette from '../../../../../utils/chart/palette'
import { getAxisYDomain } from '../../../../../utils/chart/slice'
import { roundToFourDigits } from '../../../../../utils/chart/values'
import withConfig from '../../../../wrappers/withConfig'

const DetailWaterLevelChart = ({ config }) => {
    const {
        waterLevelChartData,
        visibleChartTab,
        isLoading,
        zoomTrigger,
    } = useContext(DataContext)
    const { LINK_COLUMN } = config
    const [data, setData] = useState([])

    const [left, setLeft] = useState('dataMin')
    const [right, setRight] = useState('dataMax')
    const [refAreaLeft, setRefAreaLeft] = useState('')
    const [refAreaRight, setRefAreaRight] = useState('')
    const [top, setTop] = useState('dataMax')
    const [bottom, setBottom] = useState('dataMin')

    useEffect(() => {
        zoomOut()
    }, [zoomTrigger])

    useEffect(() => {
        if (waterLevelChartData) {
            const d = waterLevelChartData
                .map(d => ({
                    ...d,
                    MeasurementDate: dayjs(d.MeasurementDate)
                        .toDate()
                        .getTime(),
                })) // convert sample date to unix time for x-axis range
            setData(d)
        }
    }, [waterLevelChartData])

    const chartData = useMemo(() => data, [data])
    const zoom = useCallback(() => {
        let RAL = refAreaLeft
        let RAR = refAreaRight
        if (RAL === RAR || RAR === '') {
            RAL = ''
            RAL = ''
            return
        }

        // xAxis domain
        if (RAL > RAR) {
            const temp = RAL
            RAL = RAR
            RAR = temp
        }

        // yAxis domain
        const leftAxisDomain = getAxisYDomain(
            RAL,
            RAR,
            x => !x.includes('MeasurementDate') && !x.includes(LINK_COLUMN),
            0,
            chartData,
            'MeasurementDate'
        )
        setBottom(leftAxisDomain[0])
        setTop(leftAxisDomain[1])
        setLeft(RAL)
        setRight(RAR)
        setRefAreaRight('')
        setRefAreaLeft('')
    }, [refAreaLeft, refAreaRight, chartData])

    const zoomOut = useCallback(() => {
        setRefAreaLeft('')
        setRefAreaRight('')
        setLeft('dataMin')
        setRight('dataMax')
        setTop('dataMax')
        setBottom('dataMin')
    }, [chartData])

    // if loading || no data, return nothing
    if (isLoading || visibleChartTab !== 'waterLevel') {
        return null
    }
    if (!data.length) {
        return <Message text={`No Chart Data Available`} />
    }
    // construct chart
    const yAxisLabel = `Depth to Water (ft bls)`
    const xAxisLabel = 'Measurement Date'

    return (
        <div className="chart">
            <div className="chartWrapper">
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        data={chartData}
                        margin={{
                            top: 10,
                            right: 50,
                            left: 12,
                            bottom: 15,
                        }}
                        onMouseDown={e => e && e.activeLabel && setRefAreaLeft(e.activeLabel)}
                        onMouseMove={e => e && e.activeLabel && refAreaLeft && setRefAreaRight(e.activeLabel)}
                        onMouseUp={() => zoom()}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            allowDataOverflow
                            type="number"
                            dataKey="MeasurementDate"
                            domain={[left, right]}
                            tickFormatter={unixTime => dateToString(unixTime)}
                        >
                            <Label
                                value={xAxisLabel}
                                offset={-10}
                                position="insideBottom"
                                className="nitrateChartXAxisLabel"
                            />
                        </XAxis>
                        <YAxis
                            reversed={true}
                            allowDataOverflow
                            interval='preserveStartEnd'
                            type="number"
                            tickFormatter={v => roundToFourDigits(v)}
                            domain={[bottom, top]}
                        >
                            <Label
                                value={yAxisLabel}
                                angle={-90}
                                offset={20}
                                position="insideLeft"
                                className="detailChartYAxisLabel"
                            />
                        </YAxis>
                        <Tooltip content={<ChartTooltip stagger={10}/>} />
                        <Line
                            name={"Water Level (ft bls)"}
                            type="monotone"
                            dot={{ r: 1 }}
                            dataKey={`WaterLevel`}
                            stroke={palette[0]}
                            // unit={'ft bls'}
                            connectNulls 
                            strokeWidth={1}
                            activeDot={{ r: 5 }}
                            animationDuration={200}
                        />
                        {refAreaLeft && refAreaRight ? (
                            <ReferenceArea
                                x1={refAreaLeft}
                                x2={refAreaRight}
                                strokeOpacity={0.3}
                            />
                        ) : null}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default withConfig(DetailWaterLevelChart)
